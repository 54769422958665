<template>
  <div v-show="isVisible" class="overflow-auto">
    <div v-if="!props.isRootMenu" v-show="props.hasTop" class="flex items-center justify-between border-b border-border-light px-5 py-4">
      <button ref="backButton" class="flex items-center text-button-xl font-medium" @click="goBack">
        <Icon class="mr-2 size-6" name="arrow-left" />
        {{ t('w.header.menu.back') }}
      </button>
      <div v-if="props.breadcrumbs !== ''" class="ml-6 truncate text-base">
        <span>{{ props.breadcrumbs }}&nbsp;/</span>
        <span class="text-text-disabled">{{ props.menuName }}</span>
      </div>
    </div>
    <slot v-if="isVisible" />
  </div>
</template>

<script setup lang="ts">
import Icon from '~/components/icon/Icon.vue'
import { useTranslate } from '~/composables/translate'

const props = withDefaults(
  defineProps<{
    activeMenu?: string | null
    isRootMenu?: boolean
    menuName?: string | null
    hasTop?: boolean
    breadcrumbs?: string
  }>(),
  {
    activeMenu: null,
    isRootMenu: false,
    menuName: null,
    hasTop: true,
    breadcrumbs: '',
  }
)

const { t } = useTranslate()

const backButton = ref<HTMLButtonElement | null>(null)

const isVisible = computed(() => {
  return props.isRootMenu || props.activeMenu === props.menuName
})

watch(
  () => isVisible.value,
  () => {
    if (isVisible.value) {
      nextTick(() => {
        if (backButton.value) {
          backButton.value.focus()
        }
      })
    }
  }
)

const emit = defineEmits<{
  'update:activeMenu': [menuName: string]
}>()

const goBack = () => {
  emit('update:activeMenu', '')
}
</script>
