export default defineNuxtPlugin(() => {
  const {
    public: { countryId },
  } = useRuntimeConfig()

  if (countryId === 1) {
    onNuxtReady(() => {
      const script = document.createElement('script')
      script.src = `https://chat.askly.me/cw/chat/latest.js`
      script.async = true
      script.setAttribute('tw-client-key', 'sbvmgxb3i1bhoyxpphey0wss')
      document.head.appendChild(script)
    })
  }
})
