<template>
  <div class="clear-both mt-auto bg-background-light-strong sm:pt-16">
    <div class="h-container">
      <div class="-mx-5 sm:mx-0">
        <AccordionRoot
          v-if="!props.simplified"
          type="single"
          collapsible
          as-child
          class="flex flex-col flex-wrap sm:flex-row sm:justify-between md:mb-12 md:flex-nowrap md:gap-5 xl:gap-6"
        >
          <ul>
            <AccordionItem
              v-for="(footerItem, footerItemIndex) in footerData"
              :key="`bottom-link-${footerItemIndex}`"
              :value="`bottom-link-${footerItemIndex}`"
              class="border-b border-border-medium sm:w-1/2 sm:border-none sm:pb-12 md:w-1/4 md:pb-0"
              as-child
            >
              <li>
                <AccordionHeader as-child>
                  <AccordionTrigger class="group flex w-full items-center justify-between px-8 py-5 text-subtitle font-medium sm:pointer-events-none sm:mb-5 sm:p-0">
                    {{ footerItem.name }}
                    <Icon class="size-6 transition-transform ease-in-out group-data-[state=closed]:rotate-180 sm:hidden" name="chevron-up" />
                  </AccordionTrigger>
                </AccordionHeader>
                <AccordionContent class="overflow-hidden data-[state=closed]:animate-accordion-close data-[state=open]:animate-accordion-open sm:hidden">
                  <ul class="px-8 pb-1 sm:px-0 sm:pb-0">
                    <li v-for="(footerLink, footerLinkIndex) in footerItem.children" :key="`bottom-link-${footerLinkIndex}`" class="pb-3 sm:last:pb-0">
                      <NuxtLink v-if="footerLink.url" :to="footerLink.url" class="link pb-1 pt-0.5" @click="handleSamePageClick(footerLink.url)">{{ footerLink.name }}</NuxtLink>
                      <template v-else>{{ footerLink.name }}</template>
                    </li>
                  </ul>
                </AccordionContent>

                <ul class="hidden sm:block">
                  <li v-for="(footerLink, footerLinkIndex) in footerItem.children" :key="`bottom-link-${footerLinkIndex}`" class="pb-3 last:pb-0">
                    <NuxtLink v-if="footerLink.url" :to="footerLink.url" class="link pb-1 pt-0.5" @click="handleSamePageClick(footerLink.url)">{{ footerLink.name }}</NuxtLink>
                    <template v-else>{{ footerLink.name }}</template>
                  </li>
                  <li v-if="footerItemIndex === footerData.length - 1">
                    <Socials />
                  </li>
                </ul>
              </li>
            </AccordionItem>
          </ul>
        </AccordionRoot>

        <Socials class="py-3 sm:hidden" />

        <ul
          class="flex min-h-14 flex-wrap items-center justify-center gap-6 border-y border-border-medium px-4 py-2 sm:gap-4 sm:border-border md:flex-nowrap md:justify-around md:gap-2 md:px-0"
        >
          <li v-for="(logo, index) in logos" :key="index">
            <NuxtLink :to="logo.url" :title="logo.alt" class="block h-8 w-auto">
              <component :is="countryId === 1 ? LazyFooterLogosEe : countryId === 2 ? LazyFooterLogosFi : ''" :name="logo.image" class="size-[inherit]" />
            </NuxtLink>
          </li>
        </ul>

        <div class="mx-4 mb-6 mt-4 flex flex-col-reverse gap-4 px-1 text-base sm:mx-0 md:flex-row md:items-center md:gap-5">
          <div class="grow whitespace-nowrap">
            <span class="font-semibold" @click="toggleEmployeeMode">{{ t('w.footer.copyright') }}</span> |
            <span @click="toggleSiteVersion">{{ t('w.footer.copyright.text') }}</span>
          </div>

          <ul class="flex flex-wrap gap-5">
            <li>
              <NuxtLink class="link" :to="t('link.conditions.href')" @click="handleSamePageClick(t('link.conditions.href'))">{{ t('w.footer.conditions') }}</NuxtLink>
            </li>
            <li>
              <NuxtLink class="link" :to="t('link.privacy.href')" @click="handleSamePageClick(t('link.privacy.href'))">{{ t('w.footer.privacy') }}</NuxtLink>
            </li>
            <li>
              <NuxtLink class="link" :to="t('link.cookies.href')">{{ t('w.footer.cookies') }}</NuxtLink>
            </li>
          </ul>

          <NuxtLink v-if="countryId === 1" target="_blank" :to="t('link.trusted_store.href')" class="order-first block size-10 md:order-last" title="Turvaline ostukoht">
            <LazyFooterLogosEe name="turvaline-ostukoht" class="size-[inherit]" />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// TODO(Indrek): From site performance viewpoint, inlining these logos is a bit heavy-handed.
// Revert back to lazy-loading individual .svg files in <img> tags when HTTP/2 is enabled by default.

import { AccordionRoot, AccordionItem, AccordionHeader, AccordionTrigger, AccordionContent } from 'radix-vue'
import Socials from '~/components/socials/Socials.vue'
import Icon from '~/components/icon/Icon.vue'
import { useFooterLinksState } from '~/composables/states'
import { useEmployeeMode } from '~/composables/employee-mode'
import { LazyFooterLogosEe, LazyFooterLogosFi } from '#components'

const props = withDefaults(
  defineProps<{
    simplified?: boolean
  }>(),
  {
    simplified: false,
  }
)

const { t } = useTranslate()
const footerData = useFooterLinksState()
const { toggleEmployeeMode } = useEmployeeMode()
const { handleSamePageClick } = useScrollToTop()
const { toggleSiteVersion } = useSiteVersion()

const {
  public: { countryId },
} = useRuntimeConfig()

interface FooterLogo {
  alt: string
  url: string
  image: string
}

let logos: FooterLogo[] = []

switch (countryId) {
  case 1:
    logos = logos = [
      {
        alt: 'SEB',
        url: t('link.payment.href'),
        image: 'seb',
      },
      {
        alt: 'Citadele',
        url: t('link.payment.href'),
        image: 'citadele',
      },
      {
        alt: 'Swedbank',
        url: t('link.payment.href'),
        image: 'swedbank',
      },
      {
        alt: 'Luminor',
        url: t('link.payment.href'),
        image: 'luminor',
      },
      {
        alt: 'Coop',
        url: t('link.payment.href'),
        image: 'coop',
      },

      {
        alt: 'LHV',
        url: t('link.payment.href'),
        image: 'lhv',
      },

      {
        alt: 'Holm',
        url: t('link.payment.href'),
        image: 'holm',
      },
      {
        alt: 'Visa / Mastercard',
        url: t('link.payment.href'),
        image: 'krediitkaart',
      },
      {
        alt: 'Esto',
        url: t('link.payment.href'),
        image: 'esto',
      },
      {
        alt: t('link.omniva.alt'),
        url: t('link.omniva.href'),
        image: 'omniva',
      },
      {
        alt: t('link.smartpost.alt'),
        url: t('link.smartpost.href'),
        image: 'smartpost',
      },
      {
        alt: t('link.collectnet.alt'),
        url: t('link.collectnet.href'),
        image: 'dpd',
      },
      {
        alt: t('link.venipak.alt'),
        url: t('link.venipak.href'),
        image: 'venipak',
      },
    ]
    break
  case 2:
    logos = [
      {
        alt: 'Avarda',
        url: t('link.payment.href'),
        image: 'avarda',
      },
      {
        alt: 'Danske Bank',
        url: t('link.payment.href'),
        image: 'danske',
      },
      {
        alt: 'Nordea',
        url: t('link.payment.href'),
        image: 'nordea',
      },
      {
        alt: 'OP',
        url: t('link.payment.href'),
        image: 'op',
      },
      {
        alt: 'Säästöpankki',
        url: t('link.payment.href'),
        image: 'säästöpankki',
      },
      {
        alt: 'S-Pankki',
        url: t('link.payment.href'),
        image: 's-pankki',
      },
      {
        alt: 'Visa / Mastercard',
        url: t('link.payment.href'),
        image: 'krediitkaart',
      },
      {
        alt: 'Matkahuolto',
        url: t('link.transport.href'),
        image: 'matkahuolto',
      },
      {
        alt: 'PYR',
        url: t('link.transport.href'),
        image: 'matkahuolto-1',
      },
      {
        alt: 'Posti',
        url: t('link.transport.href'),
        image: 'posti',
      },
    ]
    break
}
</script>
