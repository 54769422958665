import { defineStore } from 'pinia'

type View = 'login' | 'register'

export const useLoginModalStore = defineStore('loginModal', {
  state() {
    return {
      isOpen: false,
      currentView: 'login' as View,
      text: '',
      queuedWishlistItem: null,
    }
  },
  actions: {
    open(text: string | null, view: View = 'login') {
      this.isOpen = true
      this.text = text ?? this.text
      this.currentView = view
    },
    close() {
      this.isOpen = false
      this.queuedWishlistItem = null
    },
    setIsOpen(value) {
      this.isOpen = value

      if (!value) {
        this.queuedWishlistItem = null
      }
    },
    setCurrentView(value: View) {
      this.currentView = value
    },
    setQueuedWishlistItem(productId, optionIds) {
      this.queuedWishlistItem = {
        id: productId,
        options: optionIds,
      }
    },
    useQueuedWishlistItem() {
      if (this.queuedWishlistItem) {
        $fetch('/api/account/wishlist/add?json', {
          method: 'POST',
          body: this.queuedWishlistItem,
        })
        this.queuedWishlistItem = null
      }
    },
  },
  getters: {
    getIsOpen: (state) => () => {
      return state.isOpen
    },
    getText: (state) => () => {
      return state.text
    },
    getCurrentView: (state) => () => {
      return state.currentView
    },
  },
})
