import { createSharedComposable } from '@vueuse/shared'

const LAST_VIEWED_PRODUCTS = 'lastViewedProducts'
const MAX_LAST_VIEWED_PRODUCTS = 10

interface LastViewedProduct {
  id: number
  title: string
  url: string
  image: string
}

const getFromLocalStorage = (): LastViewedProduct[] => {
  try {
    const storageValue = window.localStorage.getItem(LAST_VIEWED_PRODUCTS)

    if (storageValue) {
      const parsedValue = JSON.parse(storageValue)

      if (parsedValue) {
        return parsedValue
      }
    }
  } catch {
    // ignored
  }

  return []
}

const useLastViewedProductsState = () =>
  useState<LastViewedProduct[]>(LAST_VIEWED_PRODUCTS, () => {
    if (import.meta.client) {
      return getFromLocalStorage()
    }

    return []
  })

const addToLastViewedProducts = (product: LastViewedProduct) => {
  if (!product.id || !product.title || !product.url || !product.image) {
    return
  }

  const lastViewedProductsState = useLastViewedProductsState()
  // Shift already existing product to first place and limit the length.
  const lastViewedProducts = [product, ...lastViewedProductsState.value.filter((lvp) => lvp.id !== product.id)].slice(0, MAX_LAST_VIEWED_PRODUCTS)

  try {
    window.localStorage.setItem(LAST_VIEWED_PRODUCTS, JSON.stringify(lastViewedProducts))
  } catch {
    // User has most likely disabled local storage
  }

  lastViewedProductsState.value = lastViewedProducts
}

export const useLastViewedProducts = createSharedComposable(() => {
  const lastViewedProducts = useLastViewedProductsState()

  onMounted(() => {
    lastViewedProducts.value = getFromLocalStorage()
  })

  return {
    lastViewedProducts,
    addToLastViewedProducts,
  }
})
