<template>
  <label class="group flex cursor-pointer" :class="{ 'text-text-attention': props.isError }">
    <span
      class="relative mr-1.5 flex shrink-0 self-center"
      :class="{
        'size-5': isLarge,
        'size-4': !isLarge && !isSmall,
        'mt-0.5 size-3': isSmall,
        'text-text-light': props.disabled,
      }"
    >
      <input
        v-model="model"
        class="peer/checkbox-input-component sr-only"
        :value="props.value"
        :name="props.name"
        type="checkbox"
        :aria-label="props.label"
        .indeterminate="props.indeterminate"
        :disabled="props.disabled"
      />
      <Icon
        :name="iconName"
        class="invisible absolute inset-0 size-[inherit] fill-current peer-checked/checkbox-input-component:visible peer-indeterminate/checkbox-input-component:visible"
        :class="{
          'text-text-attention': props.isError,
        }"
      />
      <span
        class="absolute inset-0 box-border rounded-sm border-border transition-colors group-hover:border-border-hover group-focus:border-border-hover peer-focus-visible/checkbox-input-component:outline"
        :class="{
          border: !isLarge,
          'border-[1.5px]': isLarge,
          'border-text-attention': props.isError,
          'border-text-light': props.disabled,
        }"
      />
    </span>
    <slot />
  </label>
</template>

<script setup lang="ts">
import Icon from '~/components/icon/Icon.vue'

const props = withDefaults(
  defineProps<{
    label?: string
    name?: string
    modelValue: any
    value?: any
    size?: 'small' | 'large'
    isError?: boolean
    indeterminate?: boolean
    disabled?: boolean
  }>(),
  {
    value: undefined,
    size: undefined,
    isError: false,
    label: '',
    name: undefined,
    indeterminate: false,
    disabled: false,
  }
)

const isLarge = computed(() => {
  return props.size === 'large'
})

const isSmall = computed(() => {
  return props.size === 'small'
})

const iconName = computed(() => {
  let name = props.indeterminate ? 'partial' : 'check'

  if (props.size) {
    name += '-' + props.size
  }

  return name
})

const emit = defineEmits<{
  'update:modelValue': [newValue: unknown]
}>()

const model = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})
</script>
