<template>
  <div class="bg-background-medium contain-content" @mouseenter="isScrolling = false" @mouseleave="isScrolling = true">
    <div class="h-container flex min-h-10 w-full py-2 text-sm">
      <div class="relative flex w-full justify-center md:px-20">
        <div class="grid auto-rows-[1fr] md:flex md:gap-6 lg:gap-8">
          <template v-for="({ icon, message }, index) of messages" :key="`pre-header-messages-${index}`">
            <div
              class="col-start-1 row-start-1 mx-auto flex items-center md:visible md:animate-none"
              :class="{ invisible: currentMessageIndex !== index, 'animate-slide-in': slideIn, 'animate-slide-out': slideOut }"
            >
              <Icon v-if="icon" :name="icon" class="mr-1.5 size-4" />
              <span class="text-sm [&_b]:font-semibold [&_strong]:font-semibold" v-html="message" />
            </div>
            <div v-if="index !== messages.length - 1" class="hidden items-center md:flex">&middot;</div>
          </template>
        </div>
        <Languages class="absolute right-0 top-1/2 hidden -translate-y-1/2 md:block" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from '~/components/icon/Icon.vue'
import Languages from '~/components/header/Languages.vue'

const currentMessageIndex = ref(0)
const isScrolling = ref(true)

const messages = usePreHeaderState()

const slideIn = ref(false)
const slideOut = ref(false)

let timeout: NodeJS.Timeout

const animate = (currentState: 1 | 2 | 3 | 4) => {
  switch (currentState) {
    case 1:
      slideIn.value = true
      timeout = setTimeout(() => animate(2), 300)
      return
    case 2:
      slideIn.value = false
      timeout = setTimeout(() => animate(3), 2400)
      return
    case 3:
      if (!isScrolling.value) {
        timeout = setTimeout(() => animate(3), 1000)
        return
      }

      slideOut.value = true
      timeout = setTimeout(() => animate(4), 300)
      return
    case 4:
      slideOut.value = false

      currentMessageIndex.value++

      if (currentMessageIndex.value === messages.value.length) {
        currentMessageIndex.value = 0
      }

      animate(1)
      return
    default:
      throw new Error(`Invalid state: ${currentState}`)
  }
}

onMounted(() => {
  if (messages.value.length > 1) {
    animate(2)
  }
})

onBeforeUnmount(() => {
  clearTimeout(timeout)
})
</script>
