
import * as ipxRuntime$aoCX2A0Vtm from '/home/node/pood-v3-test/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 440,
    "sm": 680,
    "md": 1000,
    "lg": 1200,
    "xl": 1440,
    "xxl": 1536,
    "2xl": 1920,
    "product-md": 860
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "on24.ee",
    "on24.fi",
    "www.on24.ee",
    "www.on24.fi",
    "ru.on24.ee",
    "static.on24.ee",
    "reklaam.on24.ee"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$aoCX2A0Vtm, defaults: {} }
}
        