declare global {
  interface Window {
    criteo_q: unknown[]
  }
}

export default defineNuxtPlugin(() => {
  const {
    public: { criteoPartnerId, countryId },
  } = useRuntimeConfig()

  if (countryId !== 2 || !criteoPartnerId) {
    return
  }

  window.criteo_q = window.criteo_q || []

  onNuxtReady(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `//dynamic.criteo.com/js/ld/ld.js?a=${criteoPartnerId}`
    document.head.appendChild(script)
  })
})
