export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"alternate","hreflang":"fi-fi","href":"https://www.on24.fi/"},{"rel":"alternate","hreflang":"en-fi","href":"https://www.on24.fi/"},{"rel":"alternate","hreflang":"et-ee","href":"https://www.on24.ee/"},{"rel":"alternate","hreflang":"en-ee","href":"https://www.on24.ee/"},{"rel":"alternate","hreflang":"ru-ee","href":"https://ru.on24.ee/"},{"rel":"preload","as":"font","type":"font/woff2","href":"/fonts/Inter-300_700-7.woff2","crossorigin":"","tagPriority":"critical"},{"rel":"preload","as":"font","type":"font/woff2","href":"/fonts/Inter-300_700-6.woff2","crossorigin":"","tagPriority":"critical"},{"rel":"preload","as":"font","type":"font/woff2","href":"/fonts/Inter-300_700-2.woff2","crossorigin":"","tagPriority":"critical"}],"style":[],"script":[{"src":"https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=default%2Cdom4%2Ces2015%2Ces2016%2Ces2017%2Ces2018%2Ces2019%2Ces2020%2Ces2021%2Ces2022%2Ces5%2Ces6%2Ces7","tagPriority":"critical"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false