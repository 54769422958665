<template>
  <form @submit.prevent="register">
    <div>
      <div>
        <Input
          v-model="formName"
          name="name"
          :placeholder="t('w.register.placeholder.full_name')"
          :label="t('w.register.label.full_name')"
          autocomplete="name"
          type="text"
          field-size="small"
          hide-label
          required
        />
      </div>

      <div class="mt-2">
        <Input
          v-model="formEmail"
          name="email"
          :placeholder="t('w.login.placeholder.email')"
          :label="t('w.ostukorv.epost')"
          autocomplete="email"
          type="email"
          field-size="small"
          required
          hide-label
        />
      </div>
      <Transition enter-active-class="animate-fade-in" leave-active-class="animate-fade-out">
        <div v-show="showCodeField">
          <div class="mt-4 flex items-center">
            <div class="shrink-0 text-base">{{ t('w.register.recommendation_code_question') }}</div>
            <Input
              v-model="formRecommendationCode"
              name="recommendation-code"
              :placeholder="t('w.login.placeholder.recommendation_code')"
              :label="t('register.recommendation_code_enter')"
              type="text"
              class="ml-3"
              field-size="small"
              hide-label
            />
          </div>
          <div class="mt-4">
            <CheckboxInput v-model="formSubscribe" class="text-sm" size="small" :label="t('w.register.subscribe')">{{ t('w.register.subscribe') }}</CheckboxInput>
          </div>
        </div>
      </Transition>

      <div v-if="t('w.register.terms')" class="mt-3 text-sm [&>a:hover]:no-underline [&>a]:underline" v-html="t('w.register.terms')" />

      <RecaptchaCheckbox v-if="requiresCaptcha" v-model="captchaResponse" class="captcha mt-2" />

      <div v-if="!success && message && props.showMessages">
        <p class="mt-2 pl-4 text-sm text-text-attention" v-html="message" />
      </div>

      <div v-if="success && message && props.showMessages">
        <p class="mt-2 pl-4 text-sm text-text-strong" v-html="message" />
      </div>

      <div class="mt-6">
        <Button :is-loading="isLoading" class="btn w-full rounded-button px-6 py-3 text-button-m font-medium" type="submit">{{ t('w.global.register') }}</Button>
      </div>

      <div v-if="additionalLogins" class="mb-4">
        <div
          class="relative my-4 flex justify-center text-base before:absolute before:inset-x-0 before:top-1/2 before:h-px before:-translate-y-1/2 before:border-b before:border-border-medium"
        >
          <span class="relative inline-block bg-background px-5">{{ t('w.login.additional-delim') }}</span>
        </div>

        <RegisterFacebook is-large @click="facebookLogin" />
        <RegisterGoogle v-if="false" is-large class="mt-4" />
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import Input from '~/components/input/Input.vue'
import CheckboxInput from '~/components/input/CheckboxInput.vue'
import RegisterFacebook from '~/components/register/RegisterFacebook.vue'
import type { FetchError } from 'ofetch'
import { useSuccessfulRegistrationEmail } from '~/composables/successful-registration-email'
import { facebookLogin } from '~/lib/facebook-login'

const props = withDefaults(
  defineProps<{
    additionalLogins?: boolean
    isForwarding?: boolean
    showMessages?: boolean
  }>(),
  {
    additionalLogins: true,
    isForwarding: true,
    showMessages: true,
  }
)

const emit = defineEmits<{
  successfulRegister: []
}>()

const showCodeField = computed(() => formEmail.value && formName.value)

const { requiresCaptcha } = useShowRecaptcha()

const { t } = useTranslate()

const registrationEmail = useSuccessfulRegistrationEmail().get()

const formEmail = ref<string>(registrationEmail || '')
const formName = ref<string>('')
const formRecommendationCode = ref<string>('')
const formSubscribe = ref<boolean>(false)
const captchaResponse = ref<string | null>(null)
const success = ref<boolean>(!!registrationEmail)
const message = ref<string | null>(null)
const isLoading = ref<boolean>(false)

interface RegisterResponse {
  message: string | null
  success: boolean
}

const register = async () => {
  if (!formName.value || !formEmail.value) {
    return
  }

  isLoading.value = true

  try {
    const response = await $fetch<RegisterResponse>('/api/register?json', {
      method: 'POST',
      body: {
        name: formName.value,
        email: formEmail.value,
        recommendationCode: formRecommendationCode.value,
        captchaResponse: captchaResponse.value,
        agreesToSubscribe: formSubscribe.value,
      },
    })

    success.value = response.success
    message.value = response.message
    isLoading.value = false

    if (success.value) {
      useSuccessfulRegistrationEmail().set(formEmail.value)

      emit('successfulRegister')
    }
  } catch (error) {
    const fetchError = error as FetchError<RegisterResponse>

    success.value = false

    if (fetchError.data) {
      message.value = fetchError.data.message
    }
  }
}
</script>
