import { createGtm } from '@gtm-support/vue-gtm'

const STORE_VERSION_KEY = 'store-version'
const NUXT3_STORE_2023_VERSION_NR = 3

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ [STORE_VERSION_KEY]: NUXT3_STORE_2023_VERSION_NR })
  }

  const {
    public: { GTM_ID },
  } = useRuntimeConfig()

  const router = useRouter()

  nuxtApp.vueApp.use(
    createGtm({
      id: GTM_ID,
      vueRouter: router,
      vueRouterAdditionalEventData: () => ({
        [STORE_VERSION_KEY]: NUXT3_STORE_2023_VERSION_NR,
      }),
    })
  )
})
