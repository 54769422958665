declare global {
  interface Window {
    _mfq?: unknown[]
  }
}

export default defineNuxtPlugin(() => {
  const {
    public: { mouseflowId },
  } = useRuntimeConfig()

  if (!mouseflowId) {
    return
  }

  window._mfq = window._mfq || []

  onNuxtReady(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.defer = true
    script.src = `//cdn.mouseflow.com/projects/${mouseflowId}.js`
    document.head.appendChild(script)
  })
})
