// This cannot be inlined or else Vite will try to look for it from file system.
const TRANSLATIONS_ENDPOINT = '/i18n/translations'

export default defineNuxtPlugin(async () => {
  const { $i18n } = useNuxtApp()
  const { locale } = $i18n
  const { default: messages } = await import(/* @vite-ignore */ TRANSLATIONS_ENDPOINT)

  $i18n.mergeLocaleMessage(locale.value, messages)
})
