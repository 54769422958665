<template>
  <button
    class="fixed bottom-5 left-5 hidden size-10 items-center justify-center rounded-full border border-border bg-background p-1"
    :class="{ 'sm:flex': isEmployeeMode && isVisible }"
    type="button"
    @click="scrollToTop"
  >
    <Icon name="arrow-left" class="size-6 rotate-90 text-text" />
  </button>
</template>

<script setup lang="ts">
import Icon from '~/components/icon/Icon.vue'
import { useEmployeeMode } from '~/composables/employee-mode'

const isVisible = ref(false)

const { isEmployeeMode } = useEmployeeMode()
const { scrollToTop } = useScrollToTop()

const onScroll = useThrottleFrame(() => {
  const MIN_Y = 100
  isVisible.value = window.scrollY > MIN_Y
})

onMounted(() => {
  window.addEventListener('scroll', onScroll)
  onScroll()
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>
