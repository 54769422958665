<template>
  <template v-if="!userIsCustomizing">
    <div class="min-h-48 w-full overflow-auto text-left sm:pb-4">
      <div class="flex flex-col items-center md:flex-row">
        <img src="~/assets/icons/cookie.svg" width="64" height="64" alt="cookie" title="cookie" class="mr-2" />
        <div class="text-h2 font-light">{{ t('w.cookies.preview.title') }}</div>
      </div>

      <div class="mt-4 text-lg font-light">{{ t('w.cookies.preview.text') }}</div>
    </div>
    <div class="flex flex-col gap-5 pt-4 md:flex-row md:justify-center">
      <Button class="btn-secondary btn-rounded w-full py-3" @click="userIsCustomizing = true">{{ t('w.cookies.button.customize') }}</Button>
      <Button class="btn-rounded w-full py-3" @click="acceptAllCookies">{{ t('w.cookies.button.accept') }}</Button>
      <div class="flex justify-center md:order-first">
        <Button class="btn-flat btn-rounded py-3 underline" @click="saveAndCloseNotification">{{ t('w.cookies.button.deny') }}</Button>
      </div>
    </div>
  </template>

  <template v-else>
    <div class="flex min-h-48 w-full flex-col divide-y divide-border-medium overflow-auto pb-5 text-left">
      <div v-if="props.isCustomizing" class="pb-5">
        <div class="text-h2 font-light">{{ t('w.cookies.customize.privacy-page.title') }}</div>
        <div class="mt-5 text-lg font-light">{{ t('w.cookies.customize.privacy-page.text') }}</div>
      </div>

      <div v-else class="pb-5">
        <div class="text-h2 font-light">{{ t('w.cookies.customize.title') }}</div>
        <div class="mt-5 text-lg font-light">{{ t('w.cookies.customize.text') }}</div>
      </div>

      <div class="py-5">
        <div class="text-h4">
          {{ t('w.cookies.customize.mandatory.title') }}
          <CheckboxInput v-model="mandatoryCookiesAccepted" size="large" :label="t('w.cookies.customize.mandatory.title')" class="float-right" disabled />
        </div>
        <div class="mt-5 text-lg font-light">{{ t('w.cookies.customize.mandatory.text') }}</div>
      </div>

      <div class="py-5" @click="!props.isCustomizing && (functionalCookiesAccepted = true)">
        <div class="text-h4">
          {{ t('w.cookies.customize.functional.title') }}
          <CheckboxInput v-model="functionalCookiesAccepted" size="large" :label="t('w.cookies.customize.functional.title')" class="float-right" @click.stop />
        </div>
        <div class="mt-5 text-lg font-light">{{ t('w.cookies.customize.functional.text') }}</div>
      </div>

      <div class="py-5" @click="!props.isCustomizing && (analyticalCookiesAccepted = true)">
        <div class="text-h4">
          {{ t('w.cookies.customize.analytical.title') }}
          <CheckboxInput v-model="analyticalCookiesAccepted" size="large" :label="t('w.cookies.customize.analytical.title')" class="float-right" @click.stop />
        </div>
        <div class="mt-5 text-lg font-light">{{ t('w.cookies.customize.analytical.text') }}</div>
      </div>

      <div class="pt-5" @click="!props.isCustomizing && (marketingCookiesAccepted = true)">
        <div class="text-h4">
          {{ t('w.cookies.customize.marketing.title') }}
          <CheckboxInput v-model="marketingCookiesAccepted" size="large" :label="t('w.cookies.customize.marketing.title')" class="float-right" @click.stop />
        </div>
        <div class="mt-5 text-lg font-light">{{ t('w.cookies.customize.marketing.text') }}</div>
      </div>
    </div>

    <div class="flex flex-col gap-5 border-t border-border-medium pt-5 md:flex-row md:justify-center md:pt-8">
      <Button class="btn-secondary btn-rounded w-full py-3" @click="saveAndCloseNotification">{{ t('w.cookies.button.save') }}</Button>
      <Button v-if="!props.isCustomizing" class="btn-rounded w-full py-3" @click="acceptAllCookies">{{ t('w.cookies.button.accept') }}</Button>
    </div>
  </template>
</template>

<script setup lang="ts">
import Button from '~/components/button/Button.vue'
import { useGtm } from '@gtm-support/vue-gtm'
import CheckboxInput from '~/components/input/CheckboxInput.vue'

const { t } = useTranslate()
const consentComposable = useCookieConsent()
const gtm = useGtm()

const props = withDefaults(
  defineProps<{
    isCustomizing: boolean
  }>(),
  {
    isCustomizing: false,
  }
)

const emit = defineEmits<{
  save: []
}>()

const mandatoryCookiesAccepted = ref(consentComposable.mandatoryCookiesAccepted.value)
const functionalCookiesAccepted = ref(consentComposable.functionalCookiesAccepted.value)
const analyticalCookiesAccepted = ref(consentComposable.analyticalCookiesAccepted.value)
const marketingCookiesAccepted = ref(consentComposable.marketingCookiesAccepted.value)

// Update values when the cookie is updated, e.g. when both modal and CommercialCookiesForm are displayed on same page.
watch(
  [
    consentComposable.mandatoryCookiesAccepted,
    consentComposable.functionalCookiesAccepted,
    consentComposable.analyticalCookiesAccepted,
    consentComposable.marketingCookiesAccepted,
  ],
  () => {
    mandatoryCookiesAccepted.value = consentComposable.mandatoryCookiesAccepted.value
    functionalCookiesAccepted.value = consentComposable.functionalCookiesAccepted.value
    analyticalCookiesAccepted.value = consentComposable.analyticalCookiesAccepted.value
    marketingCookiesAccepted.value = consentComposable.marketingCookiesAccepted.value
  }
)

const userIsCustomizing = ref(props.isCustomizing)

const pushConsentsToDataLayer = () => {
  if (!gtm) {
    return
  }

  const adsConsent = marketingCookiesAccepted.value ? 'granted' : 'denied'
  const analyticalConsent = analyticalCookiesAccepted.value ? 'granted' : 'denied'
  const functionalAndOtherConsent = functionalCookiesAccepted.value ? 'granted' : 'denied' // let's use one checkbox for functionality, personalization and security for now

  gtm.trackEvent({
    event: 'consent_update',
    ad_personalization: adsConsent,
    ad_storage: adsConsent,
    ad_user_data: adsConsent,
    analytics_storage: analyticalConsent,
    functionality_storage: functionalAndOtherConsent,
    personalization_storage: functionalAndOtherConsent,
    security_storage: functionalAndOtherConsent,
  })
}

const saveAndCloseNotification = () => {
  consentComposable.saveConsent({
    functionalCookiesAccepted: functionalCookiesAccepted.value,
    analyticalCookiesAccepted: analyticalCookiesAccepted.value,
    marketingCookiesAccepted: marketingCookiesAccepted.value,
  })

  pushConsentsToDataLayer()
  emit('save')
}

const acceptAllCookies = () => {
  functionalCookiesAccepted.value = true
  analyticalCookiesAccepted.value = true
  marketingCookiesAccepted.value = true
  saveAndCloseNotification()
}
</script>
