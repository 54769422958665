<template>
  <div class="h-[138px] sm:h-20 md:h-[125px]">
    <div ref="intersectionTarget" class="pt-12" />
    <div
      class="pointer-events-none absolute inset-x-0 top-0"
      :class="{
        'bottom-0': headerScrollBottomPos === 0,
      }"
      :style="{
        height: headerScrollBottomPos !== 0 ? `${headerScrollBottomPos - 40}px` : undefined,
      }"
    >
      <div class="pointer-events-auto sticky top-0 z-30" :class="`lang-${languageId}`" @mouseenter="isHoveringHeader = true" @mouseleave="isHoveringHeader = false">
        <div class="inset-x-0 bg-background">
          <div
            class="relative z-[1] border-border-light bg-background transition-[padding] md:border-b md:py-0 md:transition-none"
            :class="{
              '!z-[1003]': opacityOverlayStore.opacityOverlay,
              'border-b py-2.5': isSearchButtonVisible,
              'py-4': !isSearchButtonVisible,
            }"
          >
            <div class="h-container relative flex items-center">
              <div class="flex items-center sm:flex-initial sm:basis-1/3">
                <HeaderMenu v-model:is-open="isOpen" class="mr-2 md:hidden" :header-links="headerLinks" :secondary-links="footerLinks" menu-container-class="h-container" />
                <Logo
                  class="transition-all"
                  :class="{
                    'max-h-[30px]': isMenuReplacingSearch || isSearchButtonVisible,
                  }"
                />
              </div>
              <div class="ml-auto sm:mx-8 sm:w-full sm:max-w-140 sm:grow">
                <div
                  class="overflow-hidden transition-[height] sm:h-12"
                  :class="{
                    'md:h-[60px]': isMenuReplacingSearch,
                    'md:h-20': !isMenuReplacingSearch,
                  }"
                >
                  <SearchForm ref="searchForm" class="md:py-4" :class="{ 'md:hidden': !topOfHeaderInViewport && !isStickySearchOpen }" :is-button-visible="isSearchButtonVisible" />
                </div>
              </div>
              <div class="sm:flex-initial sm:basis-1/3">
                <ul class="flex items-center justify-end">
                  <li class="hidden" :class="{ 'md:list-item': !topOfHeaderInViewport }">
                    <button
                      ref="toggleStickySearchButton"
                      class="relative mr-2 flex cursor-pointer rounded-full p-2 transition-colors hover:bg-background-light hover:text-text-strong"
                      :class="{ 'bg-background-light text-text-strong': isStickySearchOpen }"
                      :title="t('search.action')"
                      @click="toggleStickySearch"
                    >
                      <span class="sr-only">{{ t('search.action') }}</span>
                      <Icon name="search" class="size-6" />
                    </button>
                  </li>

                  <AccountButton :has-scrolled="!topOfHeaderInViewport" />

                  <li class="ml-2 hidden min-[360px]:list-item">
                    <NuxtLink
                      class="relative flex cursor-pointer rounded-full p-2 transition-colors hover:bg-background-light hover:text-text-strong"
                      to="/account/wishlist"
                      :aria-label="t('w.wishlist.title')"
                    >
                      <span
                        v-if="loggedIn && wishlistItemCount"
                        class="absolute right-0 top-0 box-content flex size-4 items-center justify-center rounded-full border-2 border-border-inverted bg-icon-active text-[0.75rem]/[1] text-border-inverted"
                      >
                        {{ wishlistItemCount }}
                      </span>
                      <Icon class="size-6" name="heart" />
                    </NuxtLink>
                  </li>
                  <li class="ml-2 flex">
                    <NuxtLink
                      class="relative flex rounded-full p-2 transition-colors hover:bg-background-light hover:text-text-strong"
                      to="/order/basket"
                      :aria-label="t('w.menu.ostukorv')"
                    >
                      <span
                        v-if="basketItemCount > 0"
                        class="absolute right-0 top-0 box-content flex size-4 items-center justify-center rounded-full border-2 border-border-inverted bg-icon-active text-[0.75rem]/[1] text-border-inverted"
                      >
                        {{ basketItemCount }}
                      </span>
                      <Icon class="size-6" name="cart" />
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pointer-events-auto flex w-full justify-center px-5 py-2 transition-all sm:hidden md:pb-4 md:pt-0">
        <div class="relative w-full sm:max-w-[560px]">
          <button
            type="button"
            class="flex w-full rounded-full border border-border-medium py-3 pl-11 pr-2.5 text-lg text-text-medium transition-colors placeholder:text-text-medium hover:border-border-medium-hover focus:outline-none"
            @click="openSearch"
          >
            {{ searchTerm.length === 0 ? t('header.search.placeholder') : searchTerm }}
          </button>
          <Icon name="search" class="absolute left-4 top-3 flex size-6" />
        </div>
      </div>
      <div
        class="pointer-events-none sticky inset-x-0 top-0 hidden md:block"
        :class="{
          'z-[29] md:border-b md:border-border-light': !isMenuReplacingSearch,
          'z-[31]': isMenuReplacingSearch,
        }"
      >
        <HeaderMenu
          is-desktop
          :header-links="headerLinks"
          class="h-container mx-auto"
          :class="{
            '-my-4': isMenuReplacingSearch,
          }"
          :menu-item-class="{
            'animate-fade-in py-5': isMenuReplacingSearch,
          }"
          :menu-container-class="{
            'top-[60px]': isMenuReplacingSearch,
            'top-[45px]': !isMenuReplacingSearch,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useOpacityOverlayStore } from '~/stores/opacity-overlay'
import { useBreakpoint } from '~/composables/breakpoint'
import { useHeaderStore } from '~/stores/header'
import { storeToRefs } from 'pinia'

import AccountButton from './AccountButton.vue'
import Icon from '~/components/icon/Icon.vue'
import SearchForm from './SearchForm.vue'
import HeaderMenu from '~/components/header/HeaderMenu.vue'
import Logo from '~/components/header/logo/Logo.vue'

import { useIntersectionObserver } from '@vueuse/core'
import { useBasketState, useFooterLinksState, useHeaderLinksState, useHeaderScrollBottomPosState, useUserState } from '~/composables/states'

const intersectionTarget = ref<HTMLDivElement | null>(null)
const topOfHeaderInViewport = ref<boolean>(true)

useIntersectionObserver(intersectionTarget, ([{ isIntersecting }]) => {
  topOfHeaderInViewport.value = isIntersecting
})

const { t } = useTranslate()

const user = useUserState()
const basket = useBasketState()
const headerLinks = useHeaderLinksState()
const headerScrollBottomPos = useHeaderScrollBottomPosState()
const footerLinks = useFooterLinksState()
const searchTerm = useSearchTermState()
const wishlist = useWishlistState()

const { isBreakpoint } = useBreakpoint()
const isBreakpointMd = isBreakpoint('md')

const loggedIn = !!user.value
const basketItemCount = computed(() => basket.value)
const wishlistItemCount = computed(() => wishlist.value)

const searchForm = ref<InstanceType<typeof SearchForm> | null>(null)

const {
  public: { languageId },
} = useRuntimeConfig()

const opacityOverlayStore = useOpacityOverlayStore()

const isHoveringHeader = ref(false)

const hasScrolled = ref(false)
const hasScrolledUp = ref(true)
const { isOpen } = storeToRefs(useHeaderStore())
const isSearchButtonVisible = ref(false)

const isStickySearchOpen = ref(false)

const toggleStickySearch = () => {
  isStickySearchOpen.value = !isStickySearchOpen.value
}

let lastScrollY = 0

const updateScrollRefs = () => {
  hasScrolled.value = window.scrollY !== 0
  const threshold = isSearchButtonVisible.value ? 80 : 90
  isSearchButtonVisible.value = window.scrollY > threshold
  hasScrolledUp.value = lastScrollY > window.scrollY
  lastScrollY = window.scrollY
}

const toggleStickySearchButton = ref<HTMLButtonElement | null>(null)

const handleClickOutsideSearch = (event: MouseEvent) => {
  // @ts-expect-error TODO: Fix types
  if (isStickySearchOpen.value && !event.target.closest('.site-header-search') && !toggleStickySearchButton.value?.contains(event.target)) {
    isStickySearchOpen.value = false
  }
}

const isMenuReplacingSearch = computed(() => !topOfHeaderInViewport.value && isBreakpointMd.value && !isStickySearchOpen.value)

watch(
  () => isOpen.value,
  () => {
    if (isOpen.value) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = ''
    }
  }
)

const openSearch = () => {
  searchForm.value?.openSearch()
}

const throttledUpdateScrollRefs = useThrottleFrame(updateScrollRefs)

onMounted(() => {
  window.addEventListener('scroll', throttledUpdateScrollRefs)
  nextTick(() => {
    updateScrollRefs()
  })

  watchEffect(() => {
    if (isStickySearchOpen.value) {
      nextTick(() => {
        document.addEventListener('click', handleClickOutsideSearch)
      })
    } else {
      document.removeEventListener('click', handleClickOutsideSearch)
    }
  })
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutsideSearch)
  window.removeEventListener('scroll', throttledUpdateScrollRefs)
})
</script>
