import { defineStore } from 'pinia'

export const useOpacityOverlayStore = defineStore('opacityOverlay', {
  state() {
    return {
      opacityOverlay: false,
    }
  },
  actions: {
    setOpacityOverlay(opacityOverlay: boolean) {
      this.opacityOverlay = opacityOverlay
    },
  },
})
