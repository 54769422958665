import { createSharedComposable } from '@vueuse/shared'

const IS_EMPLOYEE_MODE = 'isEmployeeMode'

const getFromLocalStorage = () => {
  try {
    const storageValue = window.localStorage.getItem(IS_EMPLOYEE_MODE)

    if (storageValue) {
      const parsedValue = JSON.parse(storageValue)

      if (parsedValue) {
        return parsedValue
      }
    }
  } catch {
    // ignored
  }

  return false
}

const useEmployeeModeState = () => useState<boolean>(IS_EMPLOYEE_MODE, () => getFromLocalStorage())

const toggleEmployeeMode = () => {
  const employeeMode = !getFromLocalStorage()
  const employeeModeState = useEmployeeModeState()

  try {
    window.localStorage.setItem(IS_EMPLOYEE_MODE, JSON.stringify(employeeMode))
    employeeModeState.value = employeeMode
  } catch {
    // User has most likely disabled local storage
  }
}

export const useEmployeeMode = createSharedComposable(() => ({
  isEmployeeMode: useEmployeeModeState(),
  toggleEmployeeMode,
}))
