import { init } from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { SENTRY_DSN },
  } = useRuntimeConfig()

  init({
    app: nuxtApp.vueApp,
    dsn: SENTRY_DSN,
    integrations: [],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tunnel: '/api/tunnel',
  })
})
