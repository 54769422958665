<template>
  <div ref="searchRoot">
    <FocusTrap v-model:active="isOpen" :initial-focus="() => searchInput ?? false" click-outside-deactivates :return-focus-on-deactivate="false">
      <form
        class="w-full sm:block"
        :class="{
          'fixed inset-0 z-30 flex flex-col bg-background md:static md:z-auto md:block md:bg-transparent': isOpen,
        }"
        method="get"
        action="/search"
        @submit.prevent="searchSubmit"
      >
        <div v-if="isOpen" class="flex items-center justify-between px-5 py-4 md:hidden">
          <Logo @click="closeSearch" />
          <button class="flex p-2" @click="closeSearch">
            <span class="sr-only">{{ t('search.close') }}</span>
            <Icon class="size-6" name="close" />
          </button>
        </div>
        <div
          class="relative flex justify-center sm:w-full sm:max-w-140"
          :class="{
            'border-b border-border-light px-5 pb-4 sm:px-0': isOpen,
          }"
        >
          <input
            id="search_q"
            ref="searchInput"
            v-model="searchTerm"
            class="w-full rounded-full border border-border-medium py-[11px] text-lg text-text-medium opacity-0 transition-colors placeholder:text-text-medium hover:border-border-medium-hover focus:outline-none sm:pl-11 sm:pr-2.5 sm:opacity-100"
            :class="{
              'block pl-11 pr-2.5 opacity-100': isOpen,
              'pointer-events-none hidden size-0 p-0 sm:pointer-events-auto sm:block sm:h-auto sm:w-full': !isOpen,
            }"
            :placeholder="t('header.search.placeholder')"
            name="q"
            maxlength="2048"
            autocomplete="off"
            @input="openSearch"
            @focus="openSearch"
            @keydown.down="focusFirstResult"
            @keydown.enter.prevent="searchSubmit"
          />
          <button
            v-show="!isOpen"
            icon-name="search"
            class="relative mr-2 flex rounded-full p-2 transition-all hover:bg-background-light hover:text-text-strong sm:hidden"
            :class="{ 'opacity-100': isOpen, 'pointer-events-none opacity-0': !props.isButtonVisible }"
            :aria-label="t('search.open')"
            @click="handleSearchClick($event)"
          >
            <span class="sr-only">{{ t('search.action') }}</span>
            <Icon class="size-6" name="search" />
          </button>
          <button
            class="absolute left-8 top-3 opacity-0 sm:left-4 sm:flex sm:opacity-100"
            :class="{
              'flex opacity-100': isOpen,
              hidden: !isOpen,
            }"
            :title="t('search.action')"
          >
            <span class="sr-only">{{ t('search.action') }}</span>
            <Icon class="size-6" name="search" />
          </button>
        </div>
        <ClientOnly>
          <SearchAsYouType
            ref="searchAsYouTypeRef"
            :class="{ hidden: !isOpen }"
            :search-term="searchTerm"
            :has-focus="isOpen"
            :is-static="!isBreakpointSm"
            @on-escape="searchInput?.focus()"
            @after-search="closeSearch"
          />
        </ClientOnly>
      </form>
    </FocusTrap>
  </div>
</template>

<script setup lang="ts">
import SearchAsYouType from './SearchAsYouType.vue'
import Icon from '~/components/icon/Icon.vue'
import { FocusTrap } from 'focus-trap-vue'
import Logo from '~/components/header/logo/Logo.vue'
import { useBreakpoint } from '~/composables/breakpoint'
import { useSearchOpenState } from '~/composables/states'
import { useStore } from '~/stores/filters'

const props = withDefaults(
  defineProps<{
    menuTopHeight?: number | undefined
    isButtonVisible?: boolean
  }>(),
  {
    menuTopHeight: undefined,
    isButtonVisible: true,
  }
)

const { isBreakpoint } = useBreakpoint()
const isBreakpointMd = isBreakpoint('md')
const isBreakpointSm = isBreakpoint('sm')

const { t } = useTranslate()

const store = useStore()

const searchTerm = useSearchTermState()
const isOpen = useSearchOpenState()
const searchInput = ref<HTMLInputElement | null>(null)
const searchRoot = ref<HTMLDivElement | null>(null)

watch(
  () => isOpen.value,
  () => {
    if (isOpen.value) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = ''
    }
  }
)

const searchAsYouTypeRef = ref<InstanceType<typeof SearchAsYouType> | null>(null)

const focusFirstResult = () => {
  searchAsYouTypeRef.value?.focusFirstResult()
}

const searchSubmit = async () => {
  await navigateTo({
    path: '/search',
    query: {
      q: searchTerm.value,
    },
  })

  store.resetFilters()
  closeSearch()
}

const openSearch = () => {
  isOpen.value = true
}

const closeSearch = () => {
  isOpen.value = false
}

const handleSearchClick = (event: Event) => {
  if (!isBreakpointMd.value) {
    event.preventDefault()
  }
  isOpen.value = true
}

defineExpose({ openSearch })
</script>
