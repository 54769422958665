<template>
  <div class="flex min-h-screen flex-col">
    <PreHeader class="shrink-0" />

    <div class="relative flex grow flex-col">
      <SiteHeader />

      <div class="h-container mx-auto my-20 text-center">
        <h1 class="mb-20 text-text-attention">{{ displayError.title || 'Error' }}</h1>

        <p class="mb-20">{{ displayError.message }}</p>

        

        <a href="javascript:window.location.reload()" class="btn btn-rounded btn-secondary mx-1 mt-4">{{ tryToRefreshButtonText }}</a>
        <a href="/" class="btn btn-rounded mx-1 mt-4">{{ backToIndexButtonText }}</a>
      </div>

      <SiteFooter />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import PreHeader from '~/components/header/PreHeader.vue'
import SiteHeader from '~/components/header/SiteHeader.vue'
import SiteFooter from '~/components/footer/SiteFooter.vue'

const props = defineProps<{
  error: NuxtError
}>()

interface SoftError {
  title: string | null
  message: string | null
  responseType: 'ErrorResponse'
}

interface DisplayError {
  title: string
  message: string
}

const {
  public: { languageId },
} = useRuntimeConfig()

const displayError = computed<DisplayError>(() => {
  if (props.error.data) {
    try {
      const parsed = JSON.parse(props.error.data)

      if (parsed.responseType === 'ErrorResponse') {
        const typed = parsed as SoftError

        return {
          title: typed.title ?? '...',
          message: typed.message ?? '...',
        }
      }
    } catch {
      // Do nothing
    }
  }

  return {
    title: 'Error :(',
    message: '',
  }
})

const tryToRefreshButtonText = computed(() => {
  switch (languageId) {
    case 1:
      return 'Proovi värskendada'
    case 2:
      return 'Yritä päivittää'
    case 3:
      return 'Попробуйте обновить'
  }

  throw Error('Unknown languageId')
})

const backToIndexButtonText = computed(() => {
  switch (languageId) {
    case 1:
      return 'Tagasi avalehele'
    case 2:
      return 'Etusivulle'
    case 3:
      return 'Вернуться домой'
  }

  throw Error('Unknown languageId')
})

useHead({
  title: 'ON24',
})
</script>
