function facebookLogin() {
  const FB = window.FB

  if (typeof FB === 'undefined') {
    return
  }

  FB.login(
    (resp) => {
      ;(async (response) => {
        if (response.status === 'connected' && response.authResponse.grantedScopes) {
          const scopesList = response.authResponse.grantedScopes.split(',')

          if (scopesList.includes('email') && scopesList.includes('public_profile')) {
            try {
              const registerResponse = await $fetch('/api/login/facebook?json', {
                method: 'POST',
                body: {
                  accessToken: response.authResponse.accessToken,
                },
              })

              if (registerResponse.loggedIn) {
                window.location.href = registerResponse.forwardTo
                return
              }
            } catch (error) {
              // Do nothing
            }
          }
        }

        await navigateTo('/login')
      })(resp)
    },
    {
      scope: 'public_profile,email',
      return_scopes: true,
      auth_type: 'rerequest',
    }
  )

  return false
}

export { facebookLogin }
