<template>
  <NuxtLink
    to="/"
    class="block h-8 w-24 fill-logo-secondary text-logo-primary transition-colors hover:fill-logo-secondary-ua hover:text-logo-primary-ua sm:mr-4 sm:h-10 sm:w-[7.5rem]"
  >
    <LazyHeaderLogoEe v-if="languageId === 1" class="pointer-events-none size-[inherit] max-h-[inherit]" preserveAspectRatio="xMinYMid meet" />
    <LazyHeaderLogoFi v-else-if="languageId === 2" class="pointer-events-none size-[inherit] max-h-[inherit]" preserveAspectRatio="xMinYMid meet" />
    <LazyHeaderLogoRu v-else-if="languageId === 3" class="pointer-events-none size-[inherit] max-h-[inherit]" preserveAspectRatio="xMinYMid meet" />
    <span class="sr-only">{{ t('p.header.logo.alt') }}</span>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useTranslate } from '~/composables/translate'

const { t } = useTranslate()
const {
  public: { languageId },
} = useRuntimeConfig()
</script>
