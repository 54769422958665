export default defineNuxtPlugin(() => {
  const {
    public: { metaPixelId },
  } = useRuntimeConfig()

  if (!metaPixelId) {
    return
  }

  const router = useRouter()

  useHead({
    noscript: [
      {
        tagPosition: 'bodyClose',
        innerHTML: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${metaPixelId}&ev=PageView&noscript=1"/>`,
      },
    ],
  })

  if (import.meta.client) {
    /* eslint-disable */
    const f = window
    // @ts-expect-error
    if (f.fbq) return
    const n = (f.fbq = function () {
      // @ts-expect-error
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    })
    // @ts-expect-error
    if (!f._fbq) f._fbq = n
    // @ts-expect-error
    n.push = n
    // @ts-expect-error
    n.loaded = true
    // @ts-expect-error
    n.version = '2.0'
    // @ts-expect-error
    n.queue = []
    /* eslint-enable */

    window.fbq('init', metaPixelId)
    window.fbq('track', 'PageView')

    router.afterEach(() => {
      window.fbq('track', 'PageView')
    })

    onNuxtReady(async () => {
      useHead({
        script: [{ src: 'https://connect.facebook.net/en_US/fbevents.js', async: true }],
      })
    })
  }
})
