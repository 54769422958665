import { defineStore } from 'pinia'

export const useHeaderStore = defineStore('header', () => {
  const isOpen = ref(false)
  const activeMenu = ref('')
  const preHeaderHeight = ref(36)
  const totalHeaderHeight = ref(153)
  const isVisible = ref(true)

  const openMenu = (menuName: string) => {
    isOpen.value = true
    activeMenu.value = menuName
  }

  return { isOpen, activeMenu, openMenu, preHeaderHeight, totalHeaderHeight, isVisible }
})
