export const useMoneyToEur = (amount: number): number => {
  try {
    return +(amount / 100000).toFixed(2)
  } catch (e) {
    if (import.meta.dev) {
      console.error(e)
    }
  }

  return 0
}

export const useCurrencyCode = () => {
  return 'EUR' // ISO 4217
}

export const useCurrencySymbol = () => {
  const { t } = useTranslate()
  return t('w.global.currency-symbol')
}

export const usePrice = (amount: number | null) => {
  const symbol = useCurrencySymbol()
  const split = ((amount ?? 0) / 100000).toFixed(2).split('.')
  const whole = split[0]
  const fractional = split[1]

  return { whole, fractional, symbol }
}

export const usePriceHtml = (amount: number | null) => {
  const { whole, fractional, symbol } = usePrice(amount)
  return `${whole},${fractional}\xA0${symbol}`
}
