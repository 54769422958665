type SafeEvent<E, T> = E & {
  readonly target: T
}

export const useSafeEvent = <T extends EventTarget, E extends Event = Event>(eventHandler: (_: SafeEvent<E, T>) => void) => {
  return (event: E) => {
    if (!event.target) {
      return
    }

    return eventHandler(event as SafeEvent<E, T>)
  }
}

type SafeFocusEvent<T, R, E> = SafeEvent<E, T> & {
  readonly target: T | null
  readonly relatedTarget: R | null
}

export const useSafeFocusEvent = <T extends EventTarget = Node, R extends EventTarget = Node, E extends FocusEvent = FocusEvent>(
  eventHandler: (_: SafeFocusEvent<T, R, E>) => void
) => {
  return (event: E) => {
    return eventHandler(event as SafeFocusEvent<T, R, E>)
  }
}
