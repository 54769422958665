import { defineStore } from 'pinia'

export declare interface TranslationUrl {
  from: string | null
  to: string | null
}

export const useTranslationUrlStore = defineStore('translationUrl', {
  state(): { translationUrl: TranslationUrl } {
    return {
      translationUrl: useTranslationUrlState().value,
    }
  },
  actions: {
    setTranslationUrl(from: string, to: string) {
      this.translationUrl = { from, to }
    },
  },
})
