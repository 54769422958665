export interface CachedPageData<T> {
  url: string
  data: T
}

export const PAGE_CONTENT_KEY = 'pageContent'

export const useApiData = <T>(key: string, url: () => string, options: any = undefined) => {
  return useAsyncData<T>(
    key,
    () => {
      const computedUrl = url()

      const { data: backendData } = useNuxtData<CachedPageData<T>>(PAGE_CONTENT_KEY)

      if (backendData.value && backendData.value !== null) {
        if (backendData.value.url === computedUrl) {
          const data = backendData.value.data
          clearNuxtData(PAGE_CONTENT_KEY)

          if (data) {
            return new Promise((resolve) => {
              resolve(data)
            })
          }
        }
      }

      return $fetch(`/api${computedUrl}`, {
        headers: useRequestHeaders(),
      })
    },
    options
  )
}
