function getLanguageCode(languageId: number) {
  switch (languageId) {
    case 1:
      return 'et_EE'
    case 2:
      return 'fi_FI'
    case 3:
      return 'ru_RU'
  }

  return 'en_US'
}

export default defineNuxtPlugin(() => {
  const {
    public: { languageId },
  } = useRuntimeConfig()

  onNuxtReady(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '490770987692073',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v17.0',
      })
    }

    const script = document.createElement('script')
    script.src = `https://connect.facebook.net/${getLanguageCode(languageId)}/sdk.js`
    script.async = true
    script.defer = true
    script.crossOrigin = 'anonymous'
    document.head.appendChild(script)
  })
})
