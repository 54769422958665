<template>
  <ConfigProvider :use-id="useIdFunction">
    <OpacityOverlay />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <LoginModal />
    <ScrollToTop />
    <CookieConsentModal />
    <ModalsContainer />
  </ConfigProvider>
</template>

<script setup lang="ts">
import { ConfigProvider } from 'radix-vue'
import { ModalsContainer } from 'vue-final-modal'
import OpacityOverlay from '~/components/app/OpacityOverlay.vue'
import LoginModal from '~/components/login-modal/LoginModal.vue'
import { useOrganization, useWebSite } from '~/composables/structured-data'
import ScrollToTop from '~/components/scroll-to-top/ScrollToTop.vue'
import CookieConsentModal from '~/components/cookie-consent/CookieConsentModal.vue'

// https://github.com/radix-vue/radix-vue/pull/718
const useIdFunction = () => useId()
provideHeadlessUseId(() => useId())

const { t } = useTranslate()
const {
  public: { languageId },
} = useRuntimeConfig()

const languageCode = languageId === 1 ? 'et' : languageId === 2 ? 'fi' : 'ru'

useHead({
  titleTemplate(title) {
    if (title) {
      return `${title} - ${t('meta.title.add')}`
    }

    return t('meta.title.add')
  },
  htmlAttrs: {
    lang: languageCode,
  },
  meta: [{ property: 'fb:app_id', content: '490770987692073' }],
})

useSchemaOrg([defineOrganization(useOrganization()), defineWebSite(useWebSite()), defineWebPage()])
</script>
