<template>
  <template v-if="loggedIn">
    <div class="overflow-auto px-5">
      <div class="block border-b border-border-light py-6">
        <span v-if="props.nameLabel" class="mb-3 block text-subheading font-medium">{{ props.nameLabel }}</span>
        <span class="flex justify-between text-base font-bold">
          <span>{{ t('w.header.menu.balance') }}:</span>
          <span>{{ usePriceHtml(userCreditBalance) }}</span>
        </span>
      </div>

      <ul class="border-b border-border-light py-1">
        <li v-for="(item, index) of accountMenu" :key="`account-link-${index}`" class="text-base">
          <NuxtLink class="inline-flex items-center py-2.5 transition-colors hover:text-text-strong" active-class="text-text-strong" :to="item.url">
            <span v-if="item.attention" class="mr-1.5 inline-block size-1.5 rounded-full bg-icon-bright" :title="t('w.account_menu.attention')" />
            {{ item.name }}
          </NuxtLink>
        </li>
      </ul>
      <div class="py-3.5 text-base">
        <a class="block py-2.5 transition-colors hover:text-text-strong" href="/api/account/logout">{{ t('w.menu.logout') }}</a>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="overflow-auto px-6 py-8">
      <ul class="border-b border-border-light pb-4">
        <li>
          <button
            class="flex w-full justify-center rounded-button border border-border px-4 py-2.5 text-base font-medium text-text transition-colors hover:border-border-hover active:border-border-active"
            @click="loginModalStore.open(t('w.login.welcome'))"
          >
            {{ t('w.header.menu.login') }}
          </button>
        </li>
        <li class="mt-4">
          <LoginFacebook />
        </li>
        <li v-if="false" class="mt-4">
          <LoginGoogle />
        </li>
      </ul>
      <div class="mt-4">
        <span class="block text-center text-subheading font-medium">{{ t('header.new-customer') }}</span>
        <Button
          class="mt-4 flex w-full items-center justify-center rounded-button bg-background-strong px-4 py-2.5 text-base text-text-inverted transition-colors hover:bg-background-strong-hover active:bg-background-strong"
          icon-name="arrow-right"
          @click="loginModalStore.open(t('w.login.welcome'), 'register')"
        >
          {{ t('w.login.register-account') }}
        </Button>
        <span class="mt-4 block text-center text-base text-text-strong">{{ t('w.header.menu.new-customer-benefit') }}</span>
      </div>
      <ul class="mt-4 flex flex-col gap-y-2 border-t border-border-light pt-4">
        <li v-for="(item, index) of additionalLinks" :key="`additional-link-${index}`">
          <button v-if="item.opensModal" class="py-1.5 text-base transition-colors hover:text-text-strong" @click="loginModalStore.open(t('w.login.welcome'))">
            {{ item.text }}
          </button>
          <NuxtLink v-else class="block py-1.5 text-base transition-colors hover:text-text-strong" :to="item.url">{{ item.text }}</NuxtLink>
        </li>
      </ul>
    </div>
  </template>
</template>

<script setup lang="ts">
import { useLoginModalStore } from '~/stores/login-modal'

import LoginFacebook from '~/components/login/buttons/LoginFacebook.vue'
import LoginGoogle from '~/components/login/buttons/LoginGoogle.vue'

const props = withDefaults(defineProps<{ nameLabel?: string }>(), { nameLabel: undefined })

const { t } = useTranslate()

const { accountMenu } = useAccountMenu()

const user = useUserState()
const loginModalStore = useLoginModalStore()

const loggedIn = !!user.value

const userCreditBalance = (user.value && user.value.balance) || 0

const additionalLinks = [
  {
    text: t('w.login.my-orders'),
    url: '/account/main',
  },
  {
    text: t('w.login.contact'),
    url: t('w.login.contact.url'),
  },
]
</script>
