<template>
  <ul class="flex items-center justify-center gap-2 sm:-ml-2 sm:justify-start sm:gap-1">
    <li v-for="(socialLink, socialLinkIndex) in socials" :key="`bottom-link-${socialLinkIndex}`">
      <NuxtLink :to="socialLink.url" class="group flex size-14 items-center justify-center rounded-full hover:bg-white sm:size-10" :title="socialLink.name" target="_blank">
        <Icon v-if="socialLink.name" :name="socialLink.name" class="size-8 group-active:text-icon-active sm:size-6" />
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup lang="ts">
import Icon from '~/components/icon/Icon.vue'

const socials = useSocialsState()
</script>
