<template>
  <NuxtImg v-if="props.src" v-bind="$attrs" :src="props.src" :format="format" />
  <img v-else v-bind="$attrs" :src="props.src" />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    src?: string | undefined
  }>(),
  {
    src: undefined,
  }
)

const format = computed(() => {
  if ((props.src || '').match(/\.(png|gif|svg)$/i)) {
    return 'png'
  }

  return 'jpeg,png'
})
</script>
