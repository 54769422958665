export const useSiteVersion = () => {
  const routeIdCookie = useCookie<string | null>('ROUTEID', { default: () => null })
  const OLD_WEBSITE = '.node2'

  const toggleSiteVersion = () => {
    routeIdCookie.value = OLD_WEBSITE
    window.location.reload()
  }

  return {
    toggleSiteVersion,
  }
}
