import revive_payload_client_4sVQNw7RlN from "/home/node/pood-v3-test/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/node/pood-v3-test/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/node/pood-v3-test/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/node/pood-v3-test/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/node/pood-v3-test/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/node/pood-v3-test/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/node/pood-v3-test/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/node/pood-v3-test/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/node/pood-v3-test/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_WPDdiystzB from "/home/node/pood-v3-test/app/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import nuxt_plugin_03YjkxYbK5 from "/home/node/pood-v3-test/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import i18n_yfWm7jX06p from "/home/node/pood-v3-test/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/node/pood-v3-test/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0000_inp_1giQlfYPa0 from "/home/node/pood-v3-test/app/plugins/0000.inp.ts";
import _001_i18n_client_EIl5yrakMO from "/home/node/pood-v3-test/app/plugins/001.i18n.client.ts";
import criteo_client_38FVmNj9u1 from "/home/node/pood-v3-test/app/plugins/criteo.client.ts";
import facebook_sdk_client_eVDmfThQ43 from "/home/node/pood-v3-test/app/plugins/facebook-sdk.client.ts";
import inner_height_client_TaxAVwmWva from "/home/node/pood-v3-test/app/plugins/inner-height.client.ts";
import kelkoo_client_f8WLuIgKI5 from "/home/node/pood-v3-test/app/plugins/kelkoo.client.ts";
import lexip_atem_VmEd8DusLI from "/home/node/pood-v3-test/app/plugins/lexip-atem.ts";
import mtg_0Ljd4QLIph from "/home/node/pood-v3-test/app/plugins/mtg.ts";
import sentry_client_shVUlIjFLk from "/home/node/pood-v3-test/app/plugins/sentry.client.ts";
import translatewise_client_Kk3GXG2HgU from "/home/node/pood-v3-test/app/plugins/translatewise.client.ts";
import vue_final_modal_pML93k5qcp from "/home/node/pood-v3-test/app/plugins/vue-final-modal.ts";
import wolfesuom_client_TJL9u25d7h from "/home/node/pood-v3-test/app/plugins/wolfesuom.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_WPDdiystzB,
  nuxt_plugin_03YjkxYbK5,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _0000_inp_1giQlfYPa0,
  _001_i18n_client_EIl5yrakMO,
  criteo_client_38FVmNj9u1,
  facebook_sdk_client_eVDmfThQ43,
  inner_height_client_TaxAVwmWva,
  kelkoo_client_f8WLuIgKI5,
  lexip_atem_VmEd8DusLI,
  mtg_0Ljd4QLIph,
  sentry_client_shVUlIjFLk,
  translatewise_client_Kk3GXG2HgU,
  vue_final_modal_pML93k5qcp,
  wolfesuom_client_TJL9u25d7h
]