<template>
  <div
    v-if="(opacityOverlayStore.opacityOverlay && props.searchTerm.length > 0 && (categories.length || products.length || showRecentProducts)) || props.isStatic"
    class="bg-background p-6 sm:flex"
    :class="{
      'absolute inset-x-14 mt-2 w-auto overflow-auto rounded-2xl shadow-lg md:inset-x-32 xl:inset-x-96 xl:px-16 xl:py-12': !props.isStatic,
      'w-full overflow-auto': props.isStatic,
    }"
    @keydown.up="focusPrevious"
    @keydown.down="focusNext"
    @keydown.esc="onEscape"
  >
    <ul v-if="!showRecentProducts" class="basis-1/2 md:pr-6 xl:pr-10">
      <li v-if="props.searchTerm !== ''" class="mb-1 font-medium xl:mb-2">{{ t('w.search.search-keyword') }}</li>
      <li v-if="props.searchTerm !== ''">
        <button
          data-result
          class="mb-6 flex w-full items-center py-1.5 text-left text-lg font-semibold transition-colors hover:text-text-strong xl:mb-8"
          :title="t('search.action')"
          @click.prevent="selectSearch"
        >
          {{ props.searchTerm }}
          <Icon name="arrow-right" class="size-5" />
        </button>
      </li>
      <li v-if="categories.length" class="mb-2 font-medium xl:mb-4">{{ t('w.search.categories') }}</li>

      <li
        v-for="(result, idx) in categories"
        :key="`autocomplete-result-category-${result.isFilter ? 'e' : 'i'}-${idx}`"
        class="cursor-pointer py-1.5 text-base hover:text-text-strong"
        :class="{
          'mt-2 xl:mt-4': !result.isFilter,
        }"
      >
        <button data-result @click.prevent="selectResult(result)">
          <span class="block text-left">
            <span v-if="result.isFilter" class="mr-1 text-text-disabled">{{ result.parentLabel }}&nbsp;/</span>
            <span v-html="getMatchedHtml(result.label)" />
          </span>
        </button>
      </li>
    </ul>
    <ul
      v-if="showRecentProducts ? recentProducts.length : products.length"
      :class="{
        'mt-6 basis-1/2 border-t border-border-light pt-6 lg:mt-0 lg:border-l lg:border-t-0 lg:pl-6 lg:pt-0 xl:pl-10': !showRecentProducts,
      }"
      class="flex flex-wrap content-start xl:-mx-2.5"
    >
      <li v-if="props.searchTerm !== '' && showRecentProducts" class="block font-medium xl:px-2.5">
        {{ t('w.search.search-keyword') }}
        <button
          v-if="props.searchTerm !== ''"
          data-result
          class="mb-6 mt-1 flex w-full items-center py-1.5 text-left text-lg font-semibold transition-colors hover:text-text-strong xl:mb-8"
          :title="t('search.action')"
          @click.prevent="selectSearch"
        >
          {{ props.searchTerm }}
          <Icon name="arrow-right" class="size-5" />
        </button>
      </li>
      <li class="mb-1 flex basis-full font-medium xl:mb-3 xl:px-2.5">
        {{ showRecentProducts ? t('w.search.recent-products') : t('w.search.products') }}
        <button
          data-result
          class="ml-auto flex items-center text-left text-sm font-normal hover:underline"
          :title="t('w.search.view-all')"
          @click.prevent="selectSearchOrLastProducts"
        >
          {{ t('w.search.view-all') }}
          <Icon name="arrow-right" class="size-5" />
        </button>
      </li>
      <li
        v-for="(result, idx) in showRecentProducts ? recentProducts : products"
        :key="`autocomplete-result-${idx}`"
        class="max-w-1/2 basis-1/2 xl:mb-6 xl:px-2.5"
        :class="{
          'md:max-w-1/4 md:basis-1/4': showRecentProducts,
        }"
      >
        <button data-result class="cursor-pointer p-2 transition-colors hover:text-text-strong" @click.prevent="selectResult(result)">
          <span class="flex flex-col items-center">
            <OnImg
              class="h-auto w-full px-4 md:px-16 lg:px-4"
              :class="{
                'md:px-4': showRecentProducts,
                'md:px-16': !showRecentProducts,
              }"
              :src="result.icon"
              :alt="result.value"
              sizes="sm:400px lg:200px"
            />
            <span class="grow pb-3 pt-2 text-base" v-html="getMatchedHtml(result.label)" />
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import OnImg from '~/components/on-img/OnImg.vue'
import Icon from '~/components/icon/Icon.vue'

import { useOpacityOverlayStore } from '~/stores/opacity-overlay'
import { useLastViewedProducts } from '~/composables/last-viewed-products'
import { useStore } from '~/stores/filters'

const props = withDefaults(
  defineProps<{
    isStatic?: boolean
    searchTerm?: string
    hasFocus?: boolean
  }>(),
  {
    isStatic: false,
    searchTerm: '',
    hasFocus: false,
  }
)

const emit = defineEmits<{
  onEscape: []
  afterSearch: []
}>()

const { t } = useTranslate()

const store = useStore()

const { lastViewedProducts } = useLastViewedProducts()

const recentProducts = lastViewedProducts.value.map((recentProduct) => ({
  section: 'products',
  label: recentProduct.title,
  value: recentProduct.title,
  icon: recentProduct.image,
  url: recentProduct.url,
}))

const opacityOverlayStore = useOpacityOverlayStore()
const maxCategoryResults = 8

const categories = ref([])
const products = ref([])

const showRecentProducts = computed(() => {
  return props.searchTerm.length < 3 || (recentProducts.length > 0 && categories.value.length === 0 && products.value.length === 0)
})

const hasFocus = computed(() => props.hasFocus && !props.isStatic)

watch(
  hasFocus,
  () => {
    if (!props.isStatic) {
      const hasOpacityOverlay = hasFocus.value
      opacityOverlayStore.setOpacityOverlay(hasOpacityOverlay)
    }
  },
  { immediate: true }
)

const processResponseData = (data) => {
  const cats = []
  const prods = []

  data.halls.forEach((item) => {
    const ignoreCaseAndSurroundingSpacesForSearchTerm = new RegExp(escapeRegExp(props.searchTerm.trim()), 'gi')
    const isResult = !!item.name.match(ignoreCaseAndSurroundingSpacesForSearchTerm)

    if (isResult) {
      cats.push({
        section: 'categories',
        label: item.name,
        value: item.name,
        url: item.url,
        worldId: item.worldId,
      })
    }

    item.childHalls.forEach((childItem) => {
      cats.push({
        section: 'categories',
        parentLabel: item.name,
        label: childItem.name,
        value: childItem.name,
        url: childItem.url,
        isFilter: true,
      })
    })
  })

  data.products.forEach((item) => {
    const price = (item.price / 1000).toString()
    const priceInteger = price.substring(0, price.length - 2)
    const priceCents = price.substring(price.length - 2, price.length)

    const res = {
      section: 'products',
      label: item.name,
      value: item.name,
      icon: item.imageUrl,
      price: [priceInteger, priceCents],
      discountPrice: null,
      url: item.url,
    }

    if (item.discountPrice) {
      const price = (item.discountPrice / 1000).toString()
      const discountInteger = price.substring(0, price.length - 2)
      const discountCents = price.substring(price.length - 2, price.length)

      res.discountPrice = [discountInteger, discountCents]
    }

    prods.push(res)
  })

  categories.value = cats.splice(0, maxCategoryResults)
  products.value = prods
}

watch([() => props.searchTerm], () => {
  if (props.searchTerm.length < 3) {
    return
  }

  $fetch(`/api/search/suggest`, {
    params: {
      q: props.searchTerm.trim(),
    },
  }).then((responseData) => {
    processResponseData(responseData)
  })
})

const escapeRegExp = (string) => {
  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\u002d')
}

const getMatchedHtml = (input) => {
  if (showRecentProducts.value) {
    return input
  }

  const ignoreCaseAndSurroundingSpacesForSearchTerm = new RegExp(escapeRegExp(props.searchTerm.trim()), 'gi')
  return input.replace(ignoreCaseAndSurroundingSpacesForSearchTerm, '<strong class="ui-autocomplete-term">$&</strong>')
}

const selectSearchOrLastProducts = async () => {
  if (showRecentProducts.value) {
    await navigateTo({
      path: '/prods/last',
    })
    emit('afterSearch')
  } else {
    await selectSearch()
  }
}

const selectSearch = async () => {
  await navigateTo({
    path: '/search',
    query: {
      q: props.searchTerm.trim(),
    },
  })

  store.resetFilters()
  emit('afterSearch')
}

const selectResult = async (result) => {
  await navigateTo({
    path: result.url,
  })

  emit('afterSearch')
}

const getFocusableResults = () => {
  return document.querySelectorAll('[data-result]')
}

const focusFirstResult = () => {
  const results = getFocusableResults()

  if (results.length > 0) {
    results[0].focus()
  }
}

const focusPrevious = () => {
  const results = Array.prototype.slice.call(getFocusableResults())
  if (results.length > 0) {
    const currentFocus = document.activeElement
    let index = results.findIndex((a) => a.isEqualNode(currentFocus)) - 1

    if (index < 0) {
      index = results.length - 1
    }

    results[index].focus()
  }
}

const focusNext = () => {
  const results = Array.prototype.slice.call(getFocusableResults())
  if (results.length > 0) {
    const currentFocus = document.activeElement
    let index = results.findIndex((a) => a.isEqualNode(currentFocus)) + 1

    if (index === results.length) {
      index = 0
    }

    results[index].focus()
  }
}

const onEscape = () => {
  emit('onEscape')
}

defineExpose({ focusFirstResult })

onUnmounted(() => {
  opacityOverlayStore.setOpacityOverlay(false)
})
</script>
