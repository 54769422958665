import { default as _91_46_46_46catchall_93JWxI3mPAfyMeta } from "/home/node/pood-v3-test/app/pages/[...catchall].vue?macro=true";
import { default as account_45balanceDiKfypfIWgMeta } from "/home/node/pood-v3-test/app/pages/account/account-balance.vue?macro=true";
import { default as indexDUobIdezR4Meta } from "/home/node/pood-v3-test/app/pages/account/index.vue?macro=true";
import { default as main30j2iVfn5BMeta } from "/home/node/pood-v3-test/app/pages/account/main.vue?macro=true";
import { default as order_45historyNv48AEuSBaMeta } from "/home/node/pood-v3-test/app/pages/account/order-history.vue?macro=true";
import { default as wishlisteqM3B3mQDKMeta } from "/home/node/pood-v3-test/app/pages/account/wishlist.vue?macro=true";
import { default as account2ChFsmILGEMeta } from "/home/node/pood-v3-test/app/pages/account.vue?macro=true";
import { default as _91slug_930lqxqDX66qMeta } from "/home/node/pood-v3-test/app/pages/c/[id]/[slug].vue?macro=true";
import { default as index3wk3d4d3gzMeta } from "/home/node/pood-v3-test/app/pages/index.vue?macro=true";
import { default as _91slug_934BC1kTd50dMeta } from "/home/node/pood-v3-test/app/pages/info/[slug].vue?macro=true";
import { default as loginGhIhXRbj9IMeta } from "/home/node/pood-v3-test/app/pages/login.vue?macro=true";
import { default as afterpaymentr03nxVohwgMeta } from "/home/node/pood-v3-test/app/pages/order/afterpayment.vue?macro=true";
import { default as avardaQnVsj9lnZPMeta } from "/home/node/pood-v3-test/app/pages/order/avarda.vue?macro=true";
import { default as basketNM316avUZKMeta } from "/home/node/pood-v3-test/app/pages/order/basket.vue?macro=true";
import { default as checkXppjOUE9IwMeta } from "/home/node/pood-v3-test/app/pages/order/check.vue?macro=true";
import { default as paymenthMzPCDs9pbMeta } from "/home/node/pood-v3-test/app/pages/order/payment.vue?macro=true";
import { default as transport0E3v8sIqWgMeta } from "/home/node/pood-v3-test/app/pages/order/transport.vue?macro=true";
import { default as _91_46_46_46catchall_93BbdjUDMtuGMeta } from "/home/node/pood-v3-test/app/pages/p/[...catchall].vue?macro=true";
import { default as _91slug_93mHmMelszLDMeta } from "/home/node/pood-v3-test/app/pages/prods/[cat]/brand/[slug].vue?macro=true";
import { default as indexLsCT7VzIl3Meta } from "/home/node/pood-v3-test/app/pages/prods/[cat]/brand/index.vue?macro=true";
import { default as _91_46_46_46catchall_93vPR9YyfzcnMeta } from "/home/node/pood-v3-test/app/pages/prods/[cat]/new/[...catchall].vue?macro=true";
import { default as _91_46_46_46catchall_935NHo4IOOQeMeta } from "/home/node/pood-v3-test/app/pages/prods/[cat]/onsale/[...catchall].vue?macro=true";
import { default as lastSc57tStIVyMeta } from "/home/node/pood-v3-test/app/pages/prods/last.vue?macro=true";
import { default as registerEw3Tj03T24Meta } from "/home/node/pood-v3-test/app/pages/register.vue?macro=true";
import { default as searchgZD7WtWLt4Meta } from "/home/node/pood-v3-test/app/pages/search.vue?macro=true";
import { default as _91slug_935949XsTRBgMeta } from "/home/node/pood-v3-test/app/pages/special/[slug].vue?macro=true";
import { default as _91slug_93QxUqXPYz1YMeta } from "/home/node/pood-v3-test/app/pages/wishlist/[slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46catchall_93JWxI3mPAfyMeta?.name ?? "catchall",
    path: _91_46_46_46catchall_93JWxI3mPAfyMeta?.path ?? "/:catchall(.*)*",
    meta: _91_46_46_46catchall_93JWxI3mPAfyMeta || {},
    alias: _91_46_46_46catchall_93JWxI3mPAfyMeta?.alias || [],
    redirect: _91_46_46_46catchall_93JWxI3mPAfyMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/[...catchall].vue").then(m => m.default || m)
  },
  {
    name: account2ChFsmILGEMeta?.name ?? undefined,
    path: account2ChFsmILGEMeta?.path ?? "/account",
    meta: account2ChFsmILGEMeta || {},
    alias: account2ChFsmILGEMeta?.alias || [],
    redirect: account2ChFsmILGEMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: account_45balanceDiKfypfIWgMeta?.name ?? "account-account-balance",
    path: account_45balanceDiKfypfIWgMeta?.path ?? "account-balance",
    meta: account_45balanceDiKfypfIWgMeta || {},
    alias: account_45balanceDiKfypfIWgMeta?.alias || [],
    redirect: account_45balanceDiKfypfIWgMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/account/account-balance.vue").then(m => m.default || m)
  },
  {
    name: indexDUobIdezR4Meta?.name ?? "account",
    path: indexDUobIdezR4Meta?.path ?? "",
    meta: indexDUobIdezR4Meta || {},
    alias: indexDUobIdezR4Meta?.alias || [],
    redirect: indexDUobIdezR4Meta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: main30j2iVfn5BMeta?.name ?? "account-main",
    path: main30j2iVfn5BMeta?.path ?? "main",
    meta: main30j2iVfn5BMeta || {},
    alias: main30j2iVfn5BMeta?.alias || [],
    redirect: main30j2iVfn5BMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/account/main.vue").then(m => m.default || m)
  },
  {
    name: order_45historyNv48AEuSBaMeta?.name ?? "account-order-history",
    path: order_45historyNv48AEuSBaMeta?.path ?? "order-history",
    meta: order_45historyNv48AEuSBaMeta || {},
    alias: order_45historyNv48AEuSBaMeta?.alias || [],
    redirect: order_45historyNv48AEuSBaMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/account/order-history.vue").then(m => m.default || m)
  },
  {
    name: wishlisteqM3B3mQDKMeta?.name ?? "account-wishlist",
    path: wishlisteqM3B3mQDKMeta?.path ?? "wishlist",
    meta: wishlisteqM3B3mQDKMeta || {},
    alias: wishlisteqM3B3mQDKMeta?.alias || [],
    redirect: wishlisteqM3B3mQDKMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_930lqxqDX66qMeta?.name ?? "c-id-slug",
    path: _91slug_930lqxqDX66qMeta?.path ?? "/c/:id()/:slug()",
    meta: _91slug_930lqxqDX66qMeta || {},
    alias: _91slug_930lqxqDX66qMeta?.alias || [],
    redirect: _91slug_930lqxqDX66qMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/c/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3wk3d4d3gzMeta?.name ?? "index",
    path: index3wk3d4d3gzMeta?.path ?? "/",
    meta: index3wk3d4d3gzMeta || {},
    alias: index3wk3d4d3gzMeta?.alias || [],
    redirect: index3wk3d4d3gzMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934BC1kTd50dMeta?.name ?? "info-slug",
    path: _91slug_934BC1kTd50dMeta?.path ?? "/info/:slug()",
    meta: _91slug_934BC1kTd50dMeta || {},
    alias: _91slug_934BC1kTd50dMeta?.alias || [],
    redirect: _91slug_934BC1kTd50dMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: loginGhIhXRbj9IMeta?.name ?? "login",
    path: loginGhIhXRbj9IMeta?.path ?? "/login",
    meta: loginGhIhXRbj9IMeta || {},
    alias: loginGhIhXRbj9IMeta?.alias || [],
    redirect: loginGhIhXRbj9IMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: afterpaymentr03nxVohwgMeta?.name ?? "order-afterpayment",
    path: afterpaymentr03nxVohwgMeta?.path ?? "/order/afterpayment",
    meta: afterpaymentr03nxVohwgMeta || {},
    alias: afterpaymentr03nxVohwgMeta?.alias || [],
    redirect: afterpaymentr03nxVohwgMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/order/afterpayment.vue").then(m => m.default || m)
  },
  {
    name: avardaQnVsj9lnZPMeta?.name ?? "order-avarda",
    path: avardaQnVsj9lnZPMeta?.path ?? "/order/avarda",
    meta: avardaQnVsj9lnZPMeta || {},
    alias: avardaQnVsj9lnZPMeta?.alias || [],
    redirect: avardaQnVsj9lnZPMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/order/avarda.vue").then(m => m.default || m)
  },
  {
    name: basketNM316avUZKMeta?.name ?? "order-basket",
    path: basketNM316avUZKMeta?.path ?? "/order/basket",
    meta: basketNM316avUZKMeta || {},
    alias: basketNM316avUZKMeta?.alias || [],
    redirect: basketNM316avUZKMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/order/basket.vue").then(m => m.default || m)
  },
  {
    name: checkXppjOUE9IwMeta?.name ?? "order-check",
    path: checkXppjOUE9IwMeta?.path ?? "/order/check",
    meta: checkXppjOUE9IwMeta || {},
    alias: checkXppjOUE9IwMeta?.alias || [],
    redirect: checkXppjOUE9IwMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/order/check.vue").then(m => m.default || m)
  },
  {
    name: paymenthMzPCDs9pbMeta?.name ?? "order-payment",
    path: paymenthMzPCDs9pbMeta?.path ?? "/order/payment",
    meta: paymenthMzPCDs9pbMeta || {},
    alias: paymenthMzPCDs9pbMeta?.alias || [],
    redirect: paymenthMzPCDs9pbMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/order/payment.vue").then(m => m.default || m)
  },
  {
    name: transport0E3v8sIqWgMeta?.name ?? "order-transport",
    path: transport0E3v8sIqWgMeta?.path ?? "/order/transport",
    meta: transport0E3v8sIqWgMeta || {},
    alias: transport0E3v8sIqWgMeta?.alias || [],
    redirect: transport0E3v8sIqWgMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/order/transport.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46catchall_93BbdjUDMtuGMeta?.name ?? "p-catchall",
    path: _91_46_46_46catchall_93BbdjUDMtuGMeta?.path ?? "/p/:catchall(.*)*",
    meta: _91_46_46_46catchall_93BbdjUDMtuGMeta || {},
    alias: _91_46_46_46catchall_93BbdjUDMtuGMeta?.alias || [],
    redirect: _91_46_46_46catchall_93BbdjUDMtuGMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/p/[...catchall].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mHmMelszLDMeta?.name ?? "prods-cat-brand-slug",
    path: _91slug_93mHmMelszLDMeta?.path ?? "/prods/:cat()/brand/:slug()",
    meta: _91slug_93mHmMelszLDMeta || {},
    alias: _91slug_93mHmMelszLDMeta?.alias || [],
    redirect: _91slug_93mHmMelszLDMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/prods/[cat]/brand/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLsCT7VzIl3Meta?.name ?? "prods-cat-brand",
    path: indexLsCT7VzIl3Meta?.path ?? "/prods/:cat()/brand",
    meta: indexLsCT7VzIl3Meta || {},
    alias: indexLsCT7VzIl3Meta?.alias || [],
    redirect: indexLsCT7VzIl3Meta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/prods/[cat]/brand/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46catchall_93vPR9YyfzcnMeta?.name ?? "prods-cat-new-catchall",
    path: _91_46_46_46catchall_93vPR9YyfzcnMeta?.path ?? "/prods/:cat()/new/:catchall(.*)*",
    meta: _91_46_46_46catchall_93vPR9YyfzcnMeta || {},
    alias: _91_46_46_46catchall_93vPR9YyfzcnMeta?.alias || [],
    redirect: _91_46_46_46catchall_93vPR9YyfzcnMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/prods/[cat]/new/[...catchall].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46catchall_935NHo4IOOQeMeta?.name ?? "prods-cat-onsale-catchall",
    path: _91_46_46_46catchall_935NHo4IOOQeMeta?.path ?? "/prods/:cat()/onsale/:catchall(.*)*",
    meta: _91_46_46_46catchall_935NHo4IOOQeMeta || {},
    alias: _91_46_46_46catchall_935NHo4IOOQeMeta?.alias || [],
    redirect: _91_46_46_46catchall_935NHo4IOOQeMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/prods/[cat]/onsale/[...catchall].vue").then(m => m.default || m)
  },
  {
    name: lastSc57tStIVyMeta?.name ?? "prods-last",
    path: lastSc57tStIVyMeta?.path ?? "/prods/last",
    meta: lastSc57tStIVyMeta || {},
    alias: lastSc57tStIVyMeta?.alias || [],
    redirect: lastSc57tStIVyMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/prods/last.vue").then(m => m.default || m)
  },
  {
    name: registerEw3Tj03T24Meta?.name ?? "register",
    path: registerEw3Tj03T24Meta?.path ?? "/register",
    meta: registerEw3Tj03T24Meta || {},
    alias: registerEw3Tj03T24Meta?.alias || [],
    redirect: registerEw3Tj03T24Meta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: searchgZD7WtWLt4Meta?.name ?? "search",
    path: searchgZD7WtWLt4Meta?.path ?? "/search",
    meta: searchgZD7WtWLt4Meta || {},
    alias: searchgZD7WtWLt4Meta?.alias || [],
    redirect: searchgZD7WtWLt4Meta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935949XsTRBgMeta?.name ?? "special-slug",
    path: _91slug_935949XsTRBgMeta?.path ?? "/special/:slug()",
    meta: _91slug_935949XsTRBgMeta || {},
    alias: _91slug_935949XsTRBgMeta?.alias || [],
    redirect: _91slug_935949XsTRBgMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/special/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QxUqXPYz1YMeta?.name ?? "wishlist-slug",
    path: _91slug_93QxUqXPYz1YMeta?.path ?? "/wishlist/:slug()",
    meta: _91slug_93QxUqXPYz1YMeta || {},
    alias: _91slug_93QxUqXPYz1YMeta?.alias || [],
    redirect: _91slug_93QxUqXPYz1YMeta?.redirect,
    component: () => import("/home/node/pood-v3-test/app/pages/wishlist/[slug].vue").then(m => m.default || m)
  }
]