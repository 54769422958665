export const useAccountMenu = () => {
  const ORDER_HISTORY_ATTENTION = 'orderHistoryAttention'
  const ACCOUNT_BALANCE_ATTENTION = 'accountBalanceAttention'

  const { t } = useTranslate()
  const { data } = useApiData('account-menu', () => '/account/main')

  const historyAttention = useOrderHistoryAttentionState(() => ({
    last: 0,
    newest: 0,
    ready: false,
  }))
  const balanceAttention = useAccountBalanceAttentionState(() => ({
    last: '',
    newest: '',
    ready: false,
  }))

  onMounted(() => {
    if (data.value && data.value.user) {
      if (data.value.user.orders.page === 1 && data.value.user.orders.entries.length > 0) {
        historyAttention.value.newest = data.value.user.orders.entries[0].tellimus
      }

      if (data.value.user.transactions.page === 1 && data.value.user.transactions.entries.length > 0) {
        balanceAttention.value.newest = data.value.user.transactions.entries[0].selgitus
      }
    }

    if (import.meta.client) {
      historyAttention.value.last = parseInt(window.localStorage.getItem(ORDER_HISTORY_ATTENTION))
      balanceAttention.value.last = window.localStorage.getItem(ACCOUNT_BALANCE_ATTENTION)

      historyAttention.value.ready = true
      balanceAttention.value.ready = true
    }
  })

  const updateHistoryAttention = (newOrderId: number) => {
    historyAttention.value.last = newOrderId
    window.localStorage.setItem(ORDER_HISTORY_ATTENTION, newOrderId.toString())
  }

  const updateBalanceAttention = (newBalanceDescription: string) => {
    balanceAttention.value.last = newBalanceDescription
    window.localStorage.setItem(ACCOUNT_BALANCE_ATTENTION, newBalanceDescription ?? '')
  }

  const accountMenu = computed(() => [
    {
      name: t('w.account.menu.main'),
      url: '/account/main',
    },
    {
      name: t('w.account.menu.order_history'),
      url: '/account/order-history',
      attention: historyAttention.value.last !== historyAttention.value.newest && historyAttention.value.ready,
    },
    {
      name: t('w.account.menu.account_balance'),
      url: '/account/account-balance',
      attention: balanceAttention.value.last !== balanceAttention.value.newest && balanceAttention.value.ready,
    },
    {
      name: t('w.account.menu.wishlist'),
      url: '/account/wishlist',
    },
    {
      name: t('w.account.menu.last'),
      url: '/prods/last',
    },
    {
      name: t('w.account.menu.basket'),
      url: '/order/basket',
    },
  ])

  return { accountMenu, updateHistoryAttention, updateBalanceAttention }
}
