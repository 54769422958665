<template>
  <div v-if="item" class="flex">
    <div
      class="-mt-6 flex w-full flex-wrap content-start"
      :class="{
        'grow basis-3/4 md:basis-3/5 2xl:basis-2/3': headerSpecialLinkTree,
      }"
    >
      <template v-if="item.children">
        <div
          v-for="(hall, index) of item.children"
          :key="`header-link-content-${index}`"
          class="mt-6 max-w-1/4 basis-1/4 pl-8 pr-3"
          :class="{
            'md:max-w-1/5 md:basis-1/5 2xl:max-w-1/6 2xl:basis-1/6': !headerSpecialLinkTree,
          }"
        >
          <div class="mb-3.5">
            <NuxtLink :to="hall.url" :title="hall.name" class="relative hover:underline" @click="doClick()">
              <HallIcon v-if="hall.icon" :name="hall.icon" class="absolute -left-8 -top-1 -m-3 size-12 text-icon-active" />
              <p class="text-base font-medium">{{ hall.name }}</p>
            </NuxtLink>
          </div>
          <div v-if="hall.children">
            <ul>
              <li v-for="(childHall, index2) of hall.children.slice(0, 5)" :key="`header-link-content-childHall-${index2}`" class="py-0.5">
                <NuxtLink :to="childHall.url" :title="childHall.name" class="hover:underline" @click="doClick()">
                  <p class="text-sm">{{ childHall.name }}</p>
                </NuxtLink>
              </li>
              <li class="mt-1 py-0.5">
                <NuxtLink :to="hall.url" :title="hall.name" class="text-link-strong hover:underline" @click="doClick()">
                  <p class="text-sm">{{ t('w.header.halls.gotoall') }}</p>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="headerSpecialLinkTree || item.image"
      class="border-l border-border-medium"
      :class="{
        flex: headerSpecialLinkTree || !item.image,
        'hidden 2xl:flex': !headerSpecialLinkTree && item.image,
      }"
    >
      <div v-if="headerSpecialLinkTree" class="pl-8">
        <p class="mb-4 text-subtitle font-medium">{{ headerSpecialLinkTree.name }}</p>
        <ul v-if="headerSpecialLinkTree.links">
          <li v-for="(childHall, index2) of headerSpecialLinkTree.links" :key="`header-link-content-childHall-${index2}`" class="whitespace-nowrap py-0.5">
            <NuxtLink :to="childHall.url" :title="childHall.name" class="text-base hover:underline" @click="doClick()">{{ childHall.name }}</NuxtLink>
          </li>
        </ul>
      </div>
      <div v-if="item.image" class="hidden pl-8 2xl:block">
        <OnImg class="rounded-lg" :src="item.image" :alt="item.name" width="176" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HeaderLink } from '~/types/api/header-link'
import type { HeaderSpecialLinkTree } from '~/types/api/header-special-link-tree'
import OnImg from '~/components/on-img/OnImg.vue'
import HallIcon from '~/components/icon/HallIcon.vue'

const { t } = useTranslate()

const props = withDefaults(
  defineProps<{
    item?: HeaderLink
  }>(),
  {
    item: undefined,
  }
)

const headerSpecialLinkTree = computed<HeaderSpecialLinkTree | null>(() => {
  if (props.item) {
    return props.item.headerSpecialLinkTree
  }

  return null
})

const emit = defineEmits<{
  linkClicked: []
}>()

const doClick = () => {
  emit('linkClicked')
}
</script>
