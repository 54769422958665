<template>
  <VueFinalModal v-model="isNotificationOpen" :z-index-base="10000000" :esc-to-close="false" :click-to-close="false" lock-scroll>
    <div class="fixed inset-0 flex">
      <div class="flex max-h-full w-full items-end justify-center p-4 sm:items-center">
        <div class="relative flex max-h-full flex-col overflow-hidden rounded-3xl bg-white p-5 text-left sm:my-8 sm:w-full sm:max-w-[800px] sm:p-10">
          <div class="flex flex-col overflow-auto px-4">
            <CookieConsent @save="isNotificationOpen = false" />
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import CookieConsent from '~/components/cookie-consent/CookieConsent.vue'

const isNotificationOpen = ref(false)
const { consentProcessPerformed } = useCookieConsent()

onMounted(() => {
  isNotificationOpen.value = !consentProcessPerformed.value
})
</script>
