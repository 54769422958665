export const useScrollToTop = () => {
  const route = useRoute()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  /* If we are on the same page already - scroll to the top - mainly because some of the footer links currently route the user to the same page and it isn't clear enough that something changed */
  const handleSamePageClick = (url: string) => {
    if (route.path === url) {
      scrollToTop()
    }
  }

  return {
    handleSamePageClick,
    scrollToTop,
  }
}
