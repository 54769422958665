export default defineNuxtPlugin(() => {
  const {
    public: { countryId },
  } = useRuntimeConfig()

  if (countryId !== 2) {
    return
  }

  onNuxtReady(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://s.kk-resources.com/leadtag.js'
    document.head.appendChild(script)
  })
})
