<template>
  <div class="fixed inset-0 z-30 hidden size-full bg-background/50" :class="{ '!block': showOpacityOverlay }" />
</template>

<script setup lang="ts">
import { useOpacityOverlayStore } from '~/stores/opacity-overlay'
import { storeToRefs } from 'pinia'

const { opacityOverlay: showOpacityOverlay } = storeToRefs(useOpacityOverlayStore())

useHead({
  htmlAttrs: {
    class: {
      'overflow-hidden': showOpacityOverlay,
    },
  },
})
</script>
