<template>
  <div v-if="languageId === 1"><NuxtLink id="translation_ru" title="RUS" :to="translationUrl">По-русски</NuxtLink></div>
  <div v-else-if="languageId === 3"><NuxtLink id="translation_ee" title="EST" :to="translationUrl">Eesti keeles</NuxtLink></div>
</template>

<script setup lang="ts">
import { useTranslationUrlStore } from '~/stores/translation-url'

const {
  public: { altSiteBase, languageId },
} = useRuntimeConfig()
const route = useRoute()

const translationUrlStore = useTranslationUrlStore()

const translationUrl = computed(() => {
  let path = '/'

  if (translationUrlStore.translationUrl && translationUrlStore.translationUrl.from === route.fullPath) {
    path = translationUrlStore.translationUrl.to
  }

  if (!path) {
    path = '/'
  }

  const m = path.match(/^https?:\/\/[^/]+(.+)$/)
  if (m && m.length === 2) {
    path = m[1]
  }

  return `${altSiteBase}${path}`
})
</script>
