export const useSuccessfulRegistrationEmail = () => {
  const state = useSuccessfulRegistrationEmailState()

  return {
    set(email: string) {
      state.value = email
    },
    get() {
      const val = state.value
      state.value = ''
      return val
    },
  }
}
