import { useMediaQuery } from '@vueuse/core'

const BREAKPOINTS = {
  xs: '440px',
  sm: '680px',
  md: '1000px',
  lg: '1200px',
  xl: '1440px',
  '2xl': '1920px',
}

type Breakpoint = keyof typeof BREAKPOINTS

export const useBreakpoint = createSharedComposable(() => {
  const grid = Object.fromEntries(Object.entries(BREAKPOINTS).map(([k, v]) => [k, useMediaQuery(() => `(min-width: ${v})`)]))
  const gridInverse = Object.fromEntries(Object.entries(BREAKPOINTS).map(([k, v]) => [k, useMediaQuery(() => `not all and (min-width: ${v})`)]))

  return {
    isBreakpoint: (breakpoint: Breakpoint) => grid[breakpoint],
    isNotBreakpoint: (breakpoint: Breakpoint) => gridInverse[breakpoint],
  }
})
