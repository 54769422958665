<template>
  <NuxtLink v-if="props.url && !props.opensMenu" class="block" :to="props.url">
    <slot />
  </NuxtLink>
  <button v-else class="block w-full transition-colors hover:text-text-strong" @click="openMenu">
    <slot />
  </button>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    url?: string
    opensMenu?: boolean
    activeMenu: string
    menuName: string
  }>(),
  {
    url: undefined,
    opensMenu: true,
  }
)

const emit = defineEmits<{
  'update:activeMenu': [menuName: string]
}>()

const openMenu = () => {
  if (props.opensMenu) {
    emit('update:activeMenu', props.menuName)
  }
}
</script>
