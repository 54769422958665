let recaptchaLoaded = false

export const useShowRecaptcha = createSharedComposable(() => {
  const requiresCaptcha = useRequiresCaptchaState()
  const { functionalCookiesAccepted } = useCookieConsent()

  if (requiresCaptcha.value) {
    const end = watch(
      functionalCookiesAccepted,
      (accepted) => {
        if (accepted) {
          useRecaptchaProvider()
          recaptchaLoaded = true
        }

        if (recaptchaLoaded) {
          nextTick(() => {
            end()
          })
        }
      },
      {
        immediate: true,
      }
    )
  }

  return { requiresCaptcha, functionalCookiesAccepted }
})
