<template>
  <a
    href="#"
    class="flex items-center justify-center rounded-button bg-facebook px-10 py-2 text-button-m font-medium text-text-inverted transition-colors hover:bg-facebook-hover active:bg-facebook"
    :class="{
      'md:py-2': !props.isLarge,
    }"
    @click.prevent="facebookLogin"
  >
    <Icon name="facebook" class="mr-3 size-6" />
    <span v-html="isRegistration ? t('w.global.register_facebook') : t('w.global.facebook-login')" />
  </a>
</template>

<script setup lang="ts">
import { facebookLogin } from '~/lib/facebook-login'
import { useTranslate } from '~/composables/translate'

const props = withDefaults(
  defineProps<{
    isLarge?: boolean
    isRegistration?: boolean
  }>(),
  {
    isLarge: false,
    isRegistration: false,
  }
)

const { t } = useTranslate()
</script>
