import type { Link } from '~/types/api/link'
import type { HeaderLink } from '~/types/api/header-link'
import type { FooterSection, PreHeaderEntry, User } from '~/types/api/initial-response'
import type { TranslationUrl } from '~/stores/translation-url'
import type { Newsletter } from '~/types/api/newsletter'
import type { ValuePropSection } from '~/types/api/value-prop-section'
import type { IVoucher } from '~/components/order/BasketTypes'
import type { SiteHat } from '~/types/api/site-hat'

type InitSignature<T> = () => T | Ref<T>

export const useOrderHistoryAttentionState = (init?: InitSignature<{ ready: boolean; last: number | undefined; newest: number | undefined }>) => useState('historyAttention', init)
export const useAccountBalanceAttentionState = (init?: InitSignature<{ ready: boolean; last: string | undefined; newest: string | undefined }>) =>
  useState('balanceAttention', init)

export const useUserState = (init?: InitSignature<User>) => useState('user', init)

export const useBasketState = (init?: InitSignature<number>) => useState('basket', init)

// export const useBasketAlsoBuyState = (init?: InitSignature<any[]>) => useState('basketAlsoBuy', init)

export const useCurrentTransportStepState = (init?: InitSignature<'transport' | 'contact' | 'payment'>) => useState('currentTransportStep', init)

export const useVoucherState = () => useState<IVoucher | null>('voucher', () => null)

export const useHeaderLinksState = (init?: InitSignature<HeaderLink[]>) => useState('headerLinks', init)

export const useFooterLinksState = (init?: InitSignature<FooterSection[]>) => useState('footerLinks', init)

export const useWishlistState = (init?: InitSignature<number>) => useState('wishlist', init)

export const useRequiresCaptchaState = (init?: InitSignature<boolean>) => useState('requiresCaptcha', init)

export const useIsFilterOpenState = () => useState('isFilterOpen', () => false)

export const useSocialsState = (init?: InitSignature<Link[]>) => useState('socials', init)

export const useTranslationUrlState = (init?: InitSignature<TranslationUrl>) => useState('translationUrl', init)

export const useIsAddReviewOpenState = () => useState('isAddReviewOpen', () => false)

export const useSearchTermState = () => useState('searchTerm', () => '')

export const useSearchOpenState = () => useState('searchOpen', () => false)

export const usePreHeaderState = (init?: InitSignature<PreHeaderEntry[]>) => useState('preHeader', init)

export const useHeaderScrollBottomPosState = () => useState('headerScrollBottomPos', () => 0)

export const useServerTranslationsState = (init?: InitSignature<Record<string, string>>) => useState('translations', init)

export const clearServerTranslationsState = () => clearNuxtState('translations')

export const useNewsletterState = (init?: InitSignature<Newsletter | null>) => useState('newsletter', init)

export const useValuePropSectionsState = (init?: InitSignature<ValuePropSection[] | null>) => useState('valuePropSections', init)

export const useSuccessfulRegistrationEmailState = () => useState('successfulRegistrationEmailState', () => '')

export const useSiteHatsState = (init?: InitSignature<SiteHat[] | null>) => useState('siteHats', init)
